<template>
  <div id="broadcastschedule" :style="dark ? 'background-color: #000; height:100%' : !dark && !isComp ? 'background-color: #000;' : ''">
    <v-slide-y-transition mode="out-in">
      <v-layout column pl-2 pr-2 :style="isMobileDevice ? '' : 'margin-left:5%;margin-right:5%'">
        <!-- ####################### SKELETON LOADERS ####################### -->
        <v-layout column v-if="loading">
          <p class="mt-4"></p>
  
          <v-skeleton-loader
            class="mx-auto"
            width="100%"
            height="60"
            type="image"
            :dark="dark"
          ></v-skeleton-loader>
          <p class="mt-0"></p>
          <v-skeleton-loader
            class="mx-auto"
            width="100%"
            height="150"
            type="image"
            :dark="dark"
          ></v-skeleton-loader>
          <p class="mt-0"></p>
          <template v-for="i in 4">
            <v-skeleton-loader
              :key="i"
              class="mx-auto"
              width="100%"
              height="60"
              type="image"
              :dark="dark"
            ></v-skeleton-loader>
            <p :key="i + 's'" class="mt-0"></p>
          </template>
  
        </v-layout>
  
        <!-- ####################### COMPONENTS ####################### -->
  
        <v-layout column v-else>

          <v-layout pa-2 column align-center justify-center style="height:100%"
            v-if="!loadingData && getEventList.length === 0 && !showReloadSchedule">
            <v-card :dark="dark" color="transparent" flat>
              <div class="text-h4 pt-4 text-center" >{{ lang[getLanguage].BROADCAST_EVENT_404 }}</div>
              <div class="text-center pt-4" ><v-icon size="100">mdi-boxing-glove</v-icon></div>
              <div class="text-h5 text-center mb-8" >{{ lang[getLanguage].BROADCAST_STAY_TUNED }}</div>
            </v-card>
          </v-layout>

          <v-layout pa-2 column align-center v-if="!loadingData && filteredEventList.length === 0 && showReloadSchedule">
            <v-card :dark="dark" color="#C40621" flat>

              <v-layout column align-center pb-4>
                <div class="text-h4 pt-4 text-center" >Could not load the Broadcast Schedule.</div>
                <div class="text-center pt-4" ><v-icon size="50">mdi-alert-circle-outline</v-icon></div>
                <div class="text-h5 text-center" >Please refresh</div>
                <v-btn 
                  class="mt-4"
                  outlined
                  style="width:350px"
                  @click="loadBroadcastSchedule()"
                >
                <v-icon>mdi-sync</v-icon>
                Reload Broadcast Schedule...
                </v-btn>
              </v-layout>
            </v-card>
          </v-layout>

          <v-layout column align-center v-if="loadingData">
            <p class="mt-2"></p>
            <v-progress-circular
                indeterminate
                color="#f8df8e"
              ></v-progress-circular>
              <div class="text-h6 mt-2" style="color:#f8df8e">{{ lang[getLanguage].LOADING }} {{ lang[getLanguage].BROADCAST_SCHEDULE }} ...</div>
          </v-layout>

          <v-card v-else-if="!loadingData && getEventList.length > 0" 
            :class="isMobileDevice ? '' : 'pa-2'" 
            :dark="dark" 
            color="transparent" 
            style="height:100%;width:100%;" 
            flat
            >
            <v-layout v-if="getEventList.length > 1 && combine === 'no'" :column="isMobileDevice" class="mt-2"> <!-- v-if="getEventList.length > 1" -->
              <v-spacer></v-spacer>

                <v-btn large class="mr-2 mb-1" v-if="checkType('UFC')" style="cursor: default;"
                       :color="dark && eventType === 'UFC' ? 'grey darken-2' : dark ? 'grey darken-4' : eventType === 'UFC' ? 'grey darken-2' : ''"
                       @click="eventTypeSwitch('UFC')">
                  <v-img
                    src="/img/logos/ufc_logo.png"
                    style="max-height: 40px; max-width: 50px"
                  ></v-img>{{ eventType === 'UFC' ? 'Show' :'' }} UFC Event
                </v-btn>

                <v-btn large class="mr-2 mb-1" v-if="checkType('BELLATOR')" style="cursor: default;"
                       :color="dark && eventType === 'BELLATOR' ? 'grey darken-2' : dark ? 'grey darken-4' : eventType === 'BELLATOR' ? 'grey darken-2' : ''"
                       @click="eventTypeSwitch('BELLATOR')">
                  <v-img
                    src="/img/logos/bellator_logo.png"
                    style="max-height: 30px; max-width: 30px"
                  ></v-img>
                  {{ eventType === 'BELLATOR' ? 'Show' :'' }} Bellator Event
                </v-btn>

                <v-btn large class="mr-2 mb-1" v-if="checkType('CAGE WARRIORS')" style="cursor: default;"
                       :color="dark && eventType === 'CAGE WARRIORS' ? 'grey darken-2' : dark ? 'grey darken-4' : eventType === 'CAGE WARRIORS' ? 'grey darken-2' : ''"
                       @click="eventTypeSwitch('CAGE WARRIORS')">
                  <v-img
                    src="/img/logos/cage_warriors_logo.png"
                    style="max-height: 30px; max-width: 30px"
                  ></v-img>
                  {{ eventType === 'CAGE WARRIORS' ? 'Show' :'' }} Cage Warriors Event
                </v-btn>

                <v-btn large v-if="checkType('ONE PRIDE')" style="cursor: default;"
                       :color="dark && eventType === 'ONE PRIDE' ? 'grey darken-2' : dark ? 'grey darken-4' : eventType === 'ONE PRIDE' ? 'grey darken-2' : ''"
                       @click="eventTypeSwitch('ONE PRIDE')">
                  <v-img
                    src="/img/logos/one_pride_logo.png"
                    style="max-height: 25px; max-width: 25px;margin-right:5px"
                  ></v-img>
                  {{ eventType === 'ONE PRIDE' ? 'Show' :'' }} One Pride Event
                </v-btn>

                <v-spacer></v-spacer>
            </v-layout>

              <!-- <div v-if="getEventList.length > 0" class="text-h4 ma-4 text-center">{{ lang[getLanguage].PREDICT_NOW }}</div> -->
              <h1 style="display:none">{{ lang[getLanguage].BROADCAST_SCHEDULE }}</h1>
              <h2 style="display:none">Upcoming UFC Fights</h2>
              <h3 style="display:none">This week's UFC Event</h3>
              
              <div v-if="getEventList.length > 1 && eventType === '' && combine === 'no'" :style="dark ? 'color:#fecd38' : 'color:#FFA000'" class="text-h6 ma-4 text-center">{{ lang[getLanguage].BROADCAST_NOTES_MORE_EVENTS }}</div>

              <div v-if="combine === 'tabs'" :style="dark ? 'color:#fecd38' : 'color:#FFA000'" class="text ma-4 text-center">{{ lang[getLanguage].BROADCAST_NOTES }}</div>

                <v-tabs v-if="(getEventList.length === 1 || (getEventList.length > 1 && eventType !== '')) && combine !== 'all'"
                  v-model="tab"
                  background-color="transparent"
                  :color="dark ? '#fecd38' : '#FFA000'"
                  grow
                >
                  <template v-for="(type1,index) in getFightTypesArr">
                    <v-tab  :key="type1+index" :href="`#${type1+index}`"  class="font-weight-bold" v-if="selectCorrectTab(type1, index)">
                      <span :style="dark ? 'color:#fecd38' : 'color:#FFA000'">{{ type1 }}</span>
                    </v-tab>
                  </template>
                </v-tabs>

                <!-- <v-tabs v-if="combine === 'all'" v-model="tab"
                  background-color="transparent"
                  :color="dark ? '#fecd38' : '#FFA000'"
                  class="d-none"
                  grow>
                    <v-tab class="font-weight-bold ">
                      <span :style="dark ? 'color:#fecd38' : 'color:#FFA000'">{{ lang[getLanguage].ALL_FIGHTS }}</span>
                    </v-tab>
                </v-tabs> -->

                <v-tabs-items v-model="tab" :style="dark ? 'background-color: #000' : 'background-color: #FFF'" v-if="combine !== 'all'">
                    <v-tab-item v-for="(type2, index) in getFightTypesArr" :key="type2+index" :value="type2+index" :style="dark ? 'margin-top:20px;background-color: #000' : 'margin-top:20px;background-color: #FFF'"
                    >
                      <template v-for="(eventItem, eventIndex) in filteredEventList">
                        <div v-if="filteredEventList[eventIndex].fights.findIndex(item => item.type === type2) > -1" :key="eventIndex + 'z'" class="text-h5 mt-2 mb-4 text-center">{{ eventItem.name }}</div>
                        <v-row :key="eventIndex + 'x'">
                            <v-col cols="12" :align="'center'" class="mb-4">
                              <span class="font-weight-normal p-0 m-0" :style="isMobileDevice ? 'font-size: 0.8rem;opacity:0.7' : 'font-size: 1rem;opacity:0.7'">{{ eventItem.venue.name + ', ' + eventItem.venue.city_name }}</span><br/>
                              <span class="font-weight-normal p-0 m-0" :style="isMobileDevice ? 'font-size: 0.8rem;opacity:0.7' : 'font-size: 1rem;opacity:0.7'">{{ makeDate(eventItem.fights[0].start) }} </span>
                            </v-col>
                            <v-col cols="12" :align="'center'" class="mb-2">
                              <span :class="isMobileDevice ? 'mt-2' : 'mt-2'" v-if="!submissionOpen(eventItem) && !submissionClosed(eventItem) && !checkFirstFightHasStarted(eventItem) && eventItem.fights[0].status === 1"
                                  dense
                                >
                                 <div style="color: #FFF"> {{ lang[getLanguage].BROADCAST_COUNTDOWN + makeDateTime(eventItem.submission_start) }} ({{ timeDifference(Math.round(eventItem.submission_start / 1000)) }})</div>
                                </span>

                                <!-- ############# "Prediction submission closed on" notification  ############# -->
                                <span :class="isMobileDevice ? 'mt-0' : 'mt-0'" v-else-if="!submissionOpen(eventItem) && submissionClosed(eventItem) && !checkLastFightHasEnded(eventItem) && eventItem.fights[0].status === 1"
                                  dense
                                >
                                 <div style="color: #FFF">{{ lang[getLanguage].BROADCAST_CLOSE_ON + makeDateTime(eventItem.submission_end) }} ({{ timeDifferenceElapsed(Math.round(eventItem.submission_end / 1000)) }})</div>
                                </span>
                                <span>
                                  <div v-if="submissionOpen(eventItem)" class="pb-2 m-0 white--text">{{ lang[getLanguage].PREDICTION_CLOSE_IN + makeDateTime(eventItem.submission_end) }} ({{ timeDifference(Math.round(eventItem.submission_end / 1000)) }})</div>
                                </span>
                            </v-col>
                          </v-row>
                        <v-row :key="eventIndex + 'l'" v-if="showLiveEventButton(eventItem)">
                          <v-col cols="12" md="12" :align="'center'" class="mb-1">
                            <v-btn 
                              :large="isMobileDevice"  
                              text 
                              style="text-transform: none !important;" 
                              color="green"
                              @click="liveLeaderboardClicked(eventItem)"
                            >
                              <v-icon large class="ml-2">mdi-access-point</v-icon>
                              <span :style="isMobileDevice && getLanguage === 'id' ? 'font-size:14px'  : 'font-size:18px'">{{ lang[getLanguage].LIVE_EVENT_LEADERBOARD}}</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <template v-for="(item, index) in filteredEventList[eventIndex].fights">
                          <v-card
                            :style="dark ? 'background: linear-gradient(180deg, rgba(18,18,18,1) 0%, rgba(23,40,84,1) 100%);' : 'background: linear-gradient(180deg, rgba(250,250,250,1) 0%, rgba(185,204,255,1) 100%);'"
                            v-if="item.type === type2 && item.status === 1 || ( item.type === type2 && item.status === 3 && (item.totalHome > 0 || item.totalAway > 0))"
                            :dark="dark"
                            class="mb-12"
                            max-height="1000"
                            :key="eventIndex+'-'+index + 'a'"
                          > 
                             <v-row v-if="combine === 'tabs' || combine === 'all'">
                               <v-col cols="12" md="12" :align="'center'">
                                  <v-btn class="mr-2 mb-1" v-if="eventItem.type === 'UFC'" style="cursor: default;"
                                      :color="dark ? 'grey darken-3' : 'grey'"
                                  >
                                    <v-img
                                      src="/img/logos/ufc_logo.png"
                                      style="max-height: 40px; max-width: 50px"
                                    ></v-img>UFC Event
                                  </v-btn>

                                  <v-btn class="mr-2 mb-1" v-else-if="eventItem.type === 'BELLATOR'" style="cursor: default;"
                                      :color="dark ? 'grey darken-3' : 'grey'"
                                  >
                                    <v-img
                                      src="/img/logos/bellator_logo.png"
                                      style="max-height: 30px; max-width: 30px"
                                    ></v-img>Bellator Event
                                  </v-btn>

                                  <v-btn class="mr-2 mb-1" v-else-if="eventItem.type === 'CAGE WARRIORS'" style="cursor: default;"
                                        :color="dark ? 'grey darken-3' : 'grey'"
                                  >
                                    <v-img
                                      src="/img/logos/cage_warriors_logo.png"
                                      style="max-height: 30px; max-width: 30px"
                                    ></v-img>Cage Warriors Event
                                  </v-btn>

                                  <v-btn class="mr-2 mb-1" v-else-if="eventItem.type === 'ONE PRIDE'" style="cursor: default;"
                                        :color="dark ? 'grey darken-3' : 'grey'"
                                  >
                                    <v-img
                                      src="/img/logos/one_pride_logo.png"
                                      style="max-height: 25px; max-width: 25px;margin-right:5px"
                                    ></v-img>One Pride Event
                                  </v-btn>
                               </v-col>
                             </v-row>

                             <v-row v-if="item.featured">
                               <v-col cols="12" md="12" :align="'center'">
                                 <div class="text-center" :style="isMobileDevice ? 'padding-top: 20px; text-transform: uppercase;margin-bottom: -20px;' :'padding-top: 20px; margin-bottom: -20px; text-transform: uppercase'">
                                   <!-- <v-icon :color="dark ? '#fecd38' : '#FFA000'">mdi-star-box</v-icon> -->
                                   <span class="special-fight" :style="dark ? 'color: #F8DF8E;margin-left:5px' : 'color:#FFA000;margin-left:5px'">{{ lang[getLanguage].SPONSORED }}</span>
                                 </div>
                                 <!-- <v-img :src="item.sponsor_logo" max-height="100" max-width="200" ></v-img> -->
                               </v-col>
                             </v-row>

                             <!-- <div v-if="isMobileDevice" class="text-center" :style="isMobileDevice ? 'margin-top: 20px;' : ''">
                              <span class="font-weight-normal" :style="isMobileDevice ? 'font-size: 1rem;margin-top:20px' : 'font-size: 1rem;'">{{ lang[getLanguage].PLAYER_FAV }}</span>
                            </div> -->

                             <v-row class="pl-1 pr-1">
                                <v-col cols="4" md="4" :align="'top'">
                                  <span class="font-weight-normal" :style="isMobileDevice ? 'font-size: 0.8rem;opacity:0.7' : 'font-size: 1rem;opacity:0.7'">{{ makeDate(item.start) }}</span>
                                </v-col>
                                
                                <v-col cols="4" md="4" >
                                  <v-layout column align-center>
                                    <!-- <span class="font-weight-normal pt-1" :style="isMobileDevice ? 'font-size: 0.8rem;opacity:0.7' : 'font-size: 1rem;opacity:0.7'">{{ makeTime(item.start) }} {{ isMobileDevice ? lang[getLanguage].LOCAL : lang[getLanguage].LOCAL_TIME }} </span> -->
                                    <v-chip :small="isMobileDevice"
                                      :class="index === 0 ? 'font-weight-bold ma-3' : 'ma-3'"
                                      :color="dark ? '#FFF' : '#CCC'"
                                      :style="dark ? 'color:#000' : 'color:#000'"
                                    >
                                    {{ index === 0 && type2 === 'Main Card' ? 'Main Event' : item.type }} <!-- when main event show Main Event last-->
                                    </v-chip>
                                  </v-layout>
                                </v-col>
                                
                                <v-col cols="4" md="4" :align="'right'" >
                                  <span class="font-weight-normal" :style="isMobileDevice ? 'font-size: 0.8rem;opacity:0.7' : 'font-size: 1rem;opacity:0.7'">{{ eventItem.venue.name + ', ' + filteredEventList[eventIndex].venue.city_name }}</span>
                                </v-col>
                              </v-row>

                              <!-- ################# Competitors ##################### -->
                              <v-layout pa-4 :style="isMobileDevice ? 'margin-top:-20px' : 'margin-top:-40px'" >
                                <!-- <div  v-if="!isMobileDevice" class="text-center"><span class="font-weight-normal" :style="isMobileDevice ? 'font-size: 1rem;' : 'font-size: 1rem;margin-bottom: 40px'">{{ lang[getLanguage].PLAYER_FAV }}</span></div> -->
                                <v-row>
                                  <v-col cols="12" md="4">
                                    <v-list-item
                                    >
                                      <v-list-item-avatar :style="item.result_outcome === 1 ? 'cursor: pointer; width:90px;height:90px;border: 4px solid #4caf50;position:static' : 'width:90px;height:90px;border: 1px solid grey;position:static; cursor: pointer'"  @click="fighterRecordClicked(item.home.id)">
                                        <v-img 
                                          v-if="getFighterList.length > 0 && getFighterThumbnail(item.home.id) !== ''" 
                                          :src="getFighterThumbnail(item.home.id)"
                                          style="cursor: pointer"
                                          >
                                        </v-img>
                                        <v-icon v-else size="90" color="grey" >mdi-account-circle</v-icon>
                                        <v-btn
                                          v-if="item.result_outcome === 1"
                                          absolute
                                          dark
                                          fab
                                          bottom
                                          color="green"
                                          x-small
                                          style="margin-left:55px;margin-bottom:25px;"
                                        >
                                          <v-icon>mdi-check</v-icon>
                                        </v-btn>
                                      </v-list-item-avatar>
                                      <!-- <v-icon v-if="item.result_outcome === 1" style="margin-top:-63px;margin-left:-10px">mdi-seal-variant</v-icon> -->
                                    
                                      <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold" :style="isMobileDevice ? 'font-size: 1rem;text-transform: uppercase;opacity:0.8' : 'font-size: 1.3rem;text-transform: uppercase;opacity:0.8'" v-html="makeLongName(item.home.name)"></v-list-item-title>
                                        <v-list-item-title style="margin-top:5px" v-html="getWinLosDraw(item.home.id)"></v-list-item-title>
                                        <v-list-item-title style="margin-left:-15px; cursor: pointer" >
                                          <v-btn text rounde @click="fighterRecordClicked(item.home.id)" >
                                            {{ lang[getLanguage].BROADCAST_FIGHTER_RECORD }}
                                            <v-icon>mdi-arrow-right-thin</v-icon>
                                          </v-btn>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-col>

                                <v-col cols="12" md="4" :align="'center'" >
                                  <v-layout column align-center :style="isMobileDevice ? '' : 'margin-top:20px'">
                                    <v-btn text small @click="pointsAllocationClicked(eventItem)">
                                      <v-icon small style="margin-right:5px">mdi-information-outline</v-icon>{{ lang[getLanguage].BROADCAST_POINTS_INFO }}
                                    </v-btn>
                                    <table style="width:100%" >
                                      <!-- <tr>
                                        <th colspan="3">
                                          <span class="font-weight-light">{{ item.home.predictions_count + item.away.predictions_count === 0 ? lang[getLanguage].BROADCAST_404 : item.home.predictions_count + item.away.predictions_count === 1 ? lang[getLanguage].PREDICTIONS_FROM_ONE : lang[getLanguage].PREDICTIONS_FROM.replace("{x}", (item.home.predictions_count + item.away.predictions_count)) }}</span>
                                        </th>
                                      </tr> -->
                                      <tr >
                                        <td style="width:48%">
                                          <v-progress-linear
                                            background-color="blue darken-3"
                                            color="grey"
                                            height="15"
                                            :value="100 - item.totalHome"
                                            rounded
                                          >
                                          <span style="font-size:10px;margin-left:0px" class="font-weight-bold">{{ item.totalHome }}%</span>
                                        </v-progress-linear>
                                      </td>
                                      <td style="width:4%">
                                      </td>
                                        <td style="width:48%">
                                          <v-progress-linear
                                            background-color="grey"
                                            color="blue darken-3"
                                            height="15"
                                            :value="item.totalAway"
                                            rounded
                                          ><span style="font-size:10px;margin-left:0px" class="font-weight-bold">{{ item.totalAway }}%</span>
                                        </v-progress-linear>
                                        </td>
                                          </tr>
                                    </table>
                                  </v-layout>
                                </v-col>

                                <v-col cols="12" md="4">
                                  <v-list-item
                                  > 
                                  <!-- <v-icon v-if="item.result_outcome === 1" style="margin-top:-63px;margin-left:100px">mdi-seal-variant</v-icon> -->
                                    <v-list-item-content style="text-align: end;">
                                      <v-list-item-title class="font-weight-bold" :style="isMobileDevice ? 'font-size: 1rem;text-transform: uppercase;opacity:0.8' : 'font-size: 1.3rem;text-transform: uppercase;opacity:0.8'" v-html="makeLongName(item.away.name)"></v-list-item-title>
                                      <v-list-item-title style="margin-top:5px" v-html="getWinLosDraw(item.away.id)"></v-list-item-title>
                                      <v-list-item-title style="margin-right:-15px" >
                                        <v-btn text rounded @click="fighterRecordClicked(item.away.id)">
                                          <v-icon>mdi-arrow-left-thin</v-icon> 
                                          {{ lang[getLanguage].BROADCAST_FIGHTER_RECORD }}
                                        </v-btn>
                                      </v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-avatar :style="item.result_outcome === 2 ? 'width:90px;height:90px;border: 4px solid #4caf50;position:static' : 'width:90px;height:90px;border: 1px solid grey;position:static'"  @click="fighterRecordClicked(item.away.id)">
                                      <v-img v-if="getFighterList.length > 0 && getFighterThumbnail(item.away.id) !== ''" :src="getFighterThumbnail(item.away.id)"></v-img>
                                      <v-icon v-else size="90" color="grey">mdi-account-circle</v-icon>
                                      <v-btn
                                          v-if="item.result_outcome === 2"
                                          absolute
                                          dark
                                          fab
                                          bottom
                                          color="green"
                                          x-small
                                          style="margin-left:55px;margin-bottom:25px;"
                                        >
                                          <v-icon>mdi-check</v-icon>
                                        </v-btn>
                                    </v-list-item-avatar>
                                    
                                  </v-list-item>
                                  
                                </v-col>
                              </v-row>
                            </v-layout>

                            <!-- ################# Predict - Submission Open/Closed ##################### -->
                            <v-row v-if="getIsLoggedIn && (submissionOpen(eventItem) || submissionClosed(eventItem) && item.predictionSubmitted) && item.status !== 3 || (item.status !== 3 && item.results_processed > 0)" :style="isMobileDevice ? 'margin-left: 2px;margin-right: 2px;margin-bottom:5px' : 'margin-left: 30px;margin-right: 30px;margin-bottom:5px'" >
                              <v-col cols="12" md="4" class="d-flex justify-center">
                                <div v-if="item.result_outcome === 0 && getIsLoggedIn">
                                <v-select  
                                  :ref="'select1'"
                                  return-object 
                                  v-model="item.predictOutcome"
                                  :items="[{name: makeName(item.home.name), id: 1}, {name: makeName(item.away.name), id: 2}, {name: lang[getLanguage].PREDICTION_DRAW, id: 3}]"
                                  item-text="name"
                                  item-value="id"
                                  :label="lang[getLanguage].PREDICTION_OUTCOME.toUpperCase()"
                                  :placeholder="lang[getLanguage].PREDICTION_OUTCOME.toUpperCase()"
                                  outlined
                                  dense
                                  :style="{
                                    border: selectedItem ? '2px solid green' : ''
                                  }"
                                  :disabled="item.predictionSubmitted && !item.changePrediction"
                                  @change="checkPredictionValid(item, index, eventIndex)"
                                  eager :menu-props="'auto, overflowY'"
                                ></v-select>
                                </div>
                                <div v-else-if="item.result_outcome > 0">
                                  <div class="font-weight-bold">{{ lang[getLanguage].PREDICTION_OUTCOME }}</div>
                                  <div v-if="item.result_outcome === 1">{{ lang[getLanguage].PREDICTION_WINNER + ': ' + makeName(item.home.name).toUpperCase() }}</div>
                                  <div v-else-if="item.result_outcome === 2">{{ lang[getLanguage].PREDICTION_WINNER + ': ' + makeName(item.away.name).toUpperCase() }}</div>
                                  <div v-else-if="item.result_outcome === 3">{{ lang[getLanguage].PREDICTION_DRAW }}</div>
                                  <template v-if="item.predictOutcome !== undefined">
                                    <div :class="typeof item.predictOutcome === undefined ? 'You made No Prediction' : item.predictOutcome.id === item.result_outcome ? 'green--text' : 'red--text'" v-if="getIsLoggedIn && getUserPredictions.length > 0">{{lang[getLanguage].YOUR_PREDICTION}}: {{ (item.predictOutcome.name).toUpperCase() }}</div>
                                  </template>
                                </div>
                                
                              </v-col>

                              <v-col cols="12" md="4" :align="'center'">
                                <v-select v-if="item.result_method === 0 && getIsLoggedIn" :ref="'select2'"
                                  return-object
                                  v-model="item.predictMethod"
                                  :items="[{name: 'KO/TKO', id: 1}, {name: lang[getLanguage].PREDICTION_SUBMISSION, id: 2}, {name: lang[getLanguage].PREDICTION_DECISION, id: 3}]"
                                  item-text="name"
                                  item-value="id"
                                  :label="lang[getLanguage].BROADCAST_HOW"
                                  :placeholder="lang[getLanguage].BROADCAST_HOW"
                                  outlined
                                  dense
                                  :disabled="item.predictionSubmitted && !item.changePrediction ? true : typeof item.predictOutcome === 'undefined' ? false : item.changePrediction ? false : item.predictOutcome.id === 3"
                                  @change="checkPredictionValid(item, index, eventIndex)"
                                  eager :menu-props="'auto, overflowY'"
                                ></v-select>
                                <div v-else-if="item.result_method > 0">
                                  <div class="font-weight-bold">{{ lang[getLanguage].BROADCAST_HOW }}</div>
                                  <div v-if="item.result_method === 1">KO/TKO</div>
                                  <div v-else-if="item.result_method === 2">{{lang[getLanguage].PREDICTION_SUBMISSION}}</div>
                                  <div v-else-if="item.result_method === 3">{{lang[getLanguage].PREDICTION_DECISION}}</div>
                                  <template v-if="item.predictMethod !== undefined">
                                    <div :class="item.predictMethod.id === item.result_method ? 'green--text' : 'red--text'" v-if="getIsLoggedIn && getUserPredictions.length > 0">{{ lang[getLanguage].YOUR_PREDICTION }}: {{ updatePredictionLanguage(item.predictMethod.name) }}</div>
                                  </template>
                                </div>
                              </v-col>

                              <v-col cols="12" md="4" :align="'center'">
                                <v-select v-if="item.result_rounds === 0 && getIsLoggedIn" :ref="'select3'"
                                  return-object
                                  v-model="item.predictRounds"
                                  :items="makeRounds(item.scheduled_rounds)"
                                  item-text="name"
                                  item-value="id"
                                  :label="lang[getLanguage].BROADCAST_WHICH_ROUND"
                                  :placeholder="lang[getLanguage].BROADCAST_WHICH_ROUND"
                                  outlined
                                  dense
                                  :disabled="item.predictionSubmitted && !item.changePrediction ? true : typeof item.predictOutcome === 'undefined' ? false : item.changePrediction ? false : item.predictOutcome.id === 3"
                                  @change="checkPredictionValid(item, index, eventIndex)"
                                  eager :menu-props="'auto, overflowY'"
                                ></v-select>
                                <div v-else-if="item.result_rounds > 0">
                                  <div class="font-weight-bold">{{ lang[getLanguage].BROADCAST_WHICH_ROUND }}</div>
                                  <div v-if="item.result_rounds === 1">{{ lang[getLanguage].PREDICTION_ROUNDS + ' ' }} 1</div>
                                  <div v-else-if="item.result_rounds === 2">{{ lang[getLanguage].PREDICTION_ROUNDS }} 2</div>
                                  <div v-else-if="item.result_rounds === 3">{{ lang[getLanguage].PREDICTION_ROUNDS }} 3</div>
                                  <div v-else-if="item.result_rounds === 4">{{ lang[getLanguage].PREDICTION_ROUNDS }} 4</div>
                                  <div v-else-if="item.result_rounds === 5">{{ lang[getLanguage].PREDICTION_ROUNDS }} 5</div>
                                  <template v-if="item.predictRounds !== undefined">
                                    <div :class="item.predictRounds.id === item.result_rounds ? 'green--text' : 'red--text'" v-if="getIsLoggedIn && getUserPredictions.length > 0">{{ lang[getLanguage].YOUR_PREDICTION }}: {{ roundLanguage(item.predictRounds.id) }}</div>
                                  </template>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row :style="isMobileDevice ? 'margin-left: 2px;margin-right: 2px;' : 'margin-left: 30px;margin-right: 30px;margin-top:-30px'">
                              <v-col cols="12" md="12">
                                <v-alert
                                  v-if="item.predictionInvalid"
                                  dense
                                  outlined
                                  type="error"
                                  v-html="lang[getLanguage].PREDICTION_ALERT"
                                >
                                </v-alert> 
                              </v-col>
                            </v-row>

                            <v-row :style="isMobileDevice ? 'margin-left: 2px;margin-right: 2px;margin-top:-20px' : 'margin-left: 30px;margin-right: 30px;margin-top:-20px'" >

                              <v-col cols="12" md="12">

                                <!-- ############# "Prediction submission Open  #############-->
                                <v-layout column v-if="getIsLoggedIn && item.predictionSubmitted && !item.changePrediction && item.status === 1 && item.results_processed === 0">
                                  <v-alert
                                    :style="isMobileDevice ? 'max-height:100px;padding-top:9px': 'max-height:46px;padding-top:9px'"
                                    color="green darken-2"
                                    class="ma-0"
                                    border="left"
                                    outlined
                                    type="success"
                                    elevation="2"
                                    icon="mdi-check"
                                  >
                                    <v-row align="center">
                                      <v-col class="grow">
                                        {{lang[getLanguage].INFO_PREDICTION}}
                                      </v-col>
                                      <v-col class="shrink" v-if="!isMobileDevice && submissionOpen(eventItem)">
                                        <v-btn 
                                          :color="dark ? '#C40721' : '#C40721'" 
                                          small 
                                          :style="dark ? 'color:#FFF; font-family: Verdana; font-weight: 800' : 'color:#FFF;'"
                                          @click="changePredictionClicked(eventIndex, index, eventItem)"
                                          >{{lang[getLanguage].CHANGE_PREDIDCTION}}
                                        </v-btn>
                                      </v-col>
                                  </v-row>
                                  </v-alert>
                                  <v-btn v-if="isMobileDevice && submissionOpen(eventItem)" class="mt-2"
                                    :color="dark ? '#C40721' : '#C40721'" 
                                    :style="dark ? 'color:#FFF; font-family: Verdana' : 'color:#FFF'"
                                    @click="changePredictionClicked(eventIndex, index, eventItem)"
                                    >{{lang[getLanguage].CHANGE_PREDIDCTION}}
                                  </v-btn>
                                  <!-- <div v-if="submissionOpen(eventItem)" class="pt-2 text-center blue--text">{{ lang[getLanguage].PREDICTION_CLOSE_IN + makeDateTime(eventItem.submission_end) }} ({{ timeDifference(Math.round(eventItem.submission_end / 1000)) }})</div> -->
                                </v-layout>
                               
                                <!--<v-btn v-else-if="getIsLoggedIn && item.predictionSubmitted && item.status === 1 && eventItem.results_processed === 0"
                                  large 
                                  color="green" 
                                  style="width:100%;
                                  color: #454545" 
                                  outlined
                                >
                                <v-icon>mdi-check</v-icon>
                                  Prediction Submitted
                                </v-btn> -->

                                <!-- ############# Submit Prediction Button Active  #####################-->
                                <!-- ############# Loading Predictions... When loading data #############-->
                                <v-layout column align-center
                                    v-else-if="getIsLoggedIn && submissionOpen(eventItem) && (!item.predictionSubmitted || item.changePrediction) && item.status === 1 && eventItem.results_processed === 0"> <!-- check predictions loaded -->
                                  <v-btn v-if="!showReloadPredictions"
                                    large 
                                    color="grey lighten-5" 
                                    style="width:100%;text-transform: none !important;
                                    color: #454545" 
                                    :loading="item.loading" 
                                    :disabled="loadingPredictionData || showReloadPredictions"
                                    @click="submitPredictionsClicked(item, index, eventItem, eventIndex)"
                                  >
                                    {{ loadingPredictionData ? 'Loading your predictions...' : item.changePrediction ? lang[getLanguage].UPDATE_PREDIDCTION : lang[getLanguage].SUBMIT_PREDICTION }}
                                    <v-icon v-if="!loadingPredictionData">mdi-arrow-right-thin</v-icon>
                                  </v-btn>

                                  <v-btn v-else-if="showReloadPredictions"
                                    large 
                                    color="#C40721" 
                                    style="width:100%;text-transform: none !important;
                                    color: #fff" 
                                    :loading="item.loading" 
                                    @click="refreshPredictions()"
                                  >
                                    {{ 'Could Not Load Predictions, please refresh' }}
                                    <v-icon v-if="!loadingPredictionData">mdi-arrow-right-thin</v-icon>
                                  </v-btn>
                                  <!-- <div v-if="submissionOpen(eventItem)" class="pt-2 blue--text">{{ lang[getLanguage].PREDICTION_CLOSE_IN + makeDateTime(eventItem.submission_end) }} ({{ timeDifference(Math.round(eventItem.submission_end / 1000)) }})</div> -->
                                </v-layout>

                                <!-- ############# "Prediction submission will open on" notification  #############-->
                                <!-- <v-alert :class="isMobileDevice ? 'mt-2' : 'mt-6'" v-if="!submissionOpen(eventItem) && !submissionClosed(eventItem) && !checkFirstFightHasStarted(eventItem) && item.status === 1"
                                  dense
                                  
                                  type="info"
                                >
                                 <div>{{ lang[getLanguage].BROADCAST_COUNTDOWN + makeDateTime(eventItem.submission_start) }} ({{ timeDifference(Math.round(eventItem.submission_start / 1000)) }})</div>
                                </v-alert> -->

                                <!-- ############# "Prediction submission closed on" notification  #############-->
                                <!-- <v-alert :class="isMobileDevice ? 'mt-2' : 'mt-6'" v-else-if="!submissionOpen(eventItem) && submissionClosed(eventItem) && !checkLastFightHasEnded(eventItem) && item.status === 1"
                                  dense
                                  
                                  type="info"
                                >
                                 <div>{{ lang[getLanguage].BROADCAST_CLOSE_ON + makeDateTime(eventItem.submission_end) }} ({{ timeDifferenceElapsed(Math.round(eventItem.submission_end / 1000)) }})</div>
                                </v-alert> -->

                                <!-- ############# Cancelled event fight  #############-->
                                <!-- <v-alert :class="isMobileDevice ? 'mt-2' : 'mt-6'" v-else-if="item.status === 3 && (item.totalHome > 0 || item.totalAway > 0)"
                                  dense
                                  
                                  type="error"
                                >
                                 <div>{{lang[getLanguage].BROADCAST_CANCELLED }}</div>
                                </v-alert> -->

                                <!-- ############# Login to make predictions  #############-->
                                <v-layout column align-center v-else-if="!getIsLoggedIn && submissionOpen(eventItem)">
                                  <v-btn
                                    large 
                                    color="#C40721" 
                                    style="width:100%;
                                    color: #fff;
                                    font-family: Verdana;
                                    font-weight: 800" 
                                    @click="loginClicked"
                                  >
                                    {{ lang[getLanguage].BROADCAST_LOGIN }}
                                    <v-icon>mdi-arrow-right-thin</v-icon>
                                  </v-btn>
                                  <!-- <div v-if="submissionOpen(eventItem)" class="pt-2 blue--text">{{ lang[getLanguage].PREDICTION_CLOSE_IN + makeDateTime(eventItem.submission_end) }} ({{ timeDifference(Math.round(eventItem.submission_end / 1000)) }}) </div> -->
                                </v-layout>

                                <!-- ############# "Event has ended" notification  #############-->
                                <v-alert :class="isMobileDevice ? 'mt-2' : 'mt-6'" v-if="!submissionOpen(eventItem) && checkLastFightHasEnded(eventItem) && item.status === 1"
                                  dense
                                  
                                  type="success"
                                >
                                 <div>{{ eventItem.results_processed > 0 ? lang[getLanguage].BROADCAST_END : lang[getLanguage].BROADCAST_AVAILABLE_SOON }}</div>
                                </v-alert>

                              </v-col>
                            </v-row>
                            <!-- END -->

                          </v-card>
                        </template>
                      </template>
                    </v-tab-item>
                </v-tabs-items>


                <v-tabs-items v-model="tab" :style="dark ? 'background-color: #000' : 'background-color: #FFF'" v-if="combine === 'all'">
                  <v-tab-item :style="dark ? 'margin-top:0px;background-color: #000' : 'margin-top:0px;background-color: #FFF'"
                    >
                      <template v-for="(eventItem, eventIndex) in filteredEventList">
                        <div :key="eventIndex">
                          <v-row>
                            <v-col cols="12" md="12" :align="'center'">
                              <!-- <v-btn class="mr-2 mb-1" v-if="eventItem.type === 'UFC'" style="cursor: default; color: white;"
                                  :color="dark ? 'grey darken-3' : 'grey'"
                              >
                                <v-img
                                  src="/img/logos/ufc_logo.png"
                                  style="max-height: 40px; max-width: 50px;"
                                ></v-img>UFC Event
                              </v-btn> -->
                            </v-col>
                          </v-row>
                          <div :class="`${isMobileDevice ? 'text-h5' : 'text-h4'} mt-2 mb-1 text-center font-weight-bold`" style="color: #F8DF8D">{{ eventItem.name }}</div>
                          <v-row>
                            <v-col cols="12" :align="'center'" class="mb-4">
                              <span class="font-weight-normal p-0 m-0" :style="isMobileDevice ? 'font-size: 0.8rem;opacity:0.7' : 'font-size: 1rem;opacity:0.7'">{{ eventItem.venue.name + ', ' + eventItem.venue.city_name }}</span><br/>
                              <span class="font-weight-normal p-0 m-0" :style="isMobileDevice ? 'font-size: 0.8rem;opacity:0.7' : 'font-size: 1rem;opacity:0.7'">{{ makeDate(eventItem.fights[eventItem.fights.length - 1].start ?? eventItem.fights[0].start) }} </span>
                            </v-col>
                            <v-col cols="12" :align="'center'" class="mb-2">
                              <span :class="isMobileDevice ? 'mt-2' : 'mt-2'" v-if="!submissionOpen(eventItem) && !submissionClosed(eventItem) && !checkFirstFightHasStarted(eventItem) && eventItem.fights[0].status === 1"
                                  dense
                                >
                                 <div style="color: #FFF"> {{ lang[getLanguage].BROADCAST_COUNTDOWN + makeDateTime(eventItem.submission_start) }} ({{ timeDifference(Math.round(eventItem.submission_start / 1000)) }})</div>
                                </span>

                                <!-- ############# "Prediction submission closed on" notification  ############# -->
                                <span :class="isMobileDevice ? 'mt-0' : 'mt-0'" v-else-if="!submissionOpen(eventItem) && submissionClosed(eventItem) && !checkLastFightHasEnded(eventItem) && eventItem.fights[0].status === 1"
                                  dense
                                >
                                 <div style="color: #FFF">{{ lang[getLanguage].BROADCAST_CLOSE_ON + makeDateTime(eventItem.submission_end) }} ({{ timeDifferenceElapsed(Math.round(eventItem.submission_end / 1000)) }})</div>
                                </span>
                                <span>
                                  <div v-if="submissionOpen(eventItem)" class="pb-2 m-0 white--text">{{ lang[getLanguage].PREDICTION_CLOSE_IN + makeDateTime(eventItem.submission_end) }} ({{ timeDifference(Math.round(eventItem.submission_end / 1000)) }})</div>
                                </span>
                            </v-col>
                          </v-row>
                          <v-row :key="eventIndex + 'l'" v-if="showLiveEventButton(eventItem)">
                          <v-col cols="12" md="12" :align="'center'" class="mb-1">
                            <!-- {{ eventItem }} -->
                            <v-btn 
                              :large="isMobileDevice" 
                              text 
                              style="text-transform: none !important;" 
                              color="green"
                              @click="liveLeaderboardClicked(eventItem)"
                            >
                              <v-icon large class="ml-2">mdi-access-point</v-icon>
                              <span :style="isMobileDevice && getLanguage === 'id' ? 'font-size:14px'  : 'font-size:18px'">{{ lang[getLanguage].LIVE_EVENT_LEADERBOARD}}</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                          <template v-for="(item, index) in eventItem.fights" >
                            <v-card
                            :style="{
                              background: dark ? 'linear-gradient(180deg, rgba(18,18,18,1) 0%, rgba(23,40,84,1) 100%);' : 'background: linear-gradient(180deg, rgba(250,250,250,1) 0%, rgba(185,204,255,1) 100%);',
                              border: item.featured ? '5px solid #F8DF8E' : ''
                            }"
                            :class="item.featured ? 'specialFight' : 'specialFight'"
                            v-if="item.status === 1 || ( item.status === 3 && (item.totalHome > 0 || item.totalAway > 0))"
                            :dark="dark"
                            class="mb-12"
                            max-height="1200"
                            :key="item.id"
                          > 
                             <v-row>
                               <v-col cols="12" md="12" :align="'center'" class="d-none">
                                  <v-btn class="mr-2 mb-1" v-if="eventItem.type === 'UFC'" style="cursor: default;"
                                      :color="dark ? 'grey darken-3' : 'grey'"
                                  >
                                    <v-img
                                      src="/img/logos/ufc_logo.png"
                                      style="max-height: 40px; max-width: 50px"
                                    ></v-img>UFC Event
                                  </v-btn>

                                  <v-btn class="mr-2 mb-1" v-else-if="eventItem.type === 'BELLATOR'" style="cursor: default;"
                                      :color="dark ? 'grey darken-3' : 'grey'"
                                  >
                                    <v-img
                                      src="/img/logos/bellator_logo.png"
                                      style="max-height: 30px; max-width: 30px"
                                    ></v-img>Bellator Event
                                  </v-btn>

                                  <v-btn large class="mr-2 mb-1" v-else-if="eventItem.type === 'CAGE WARRIORS'" style="cursor: default;"
                                        :color="dark ? 'grey darken-3' : 'grey'"
                                  >
                                    <v-img
                                      src="/img/logos/cage_warriors_logo.png"
                                      style="max-height: 30px; max-width: 30px"
                                    ></v-img>Cage Warriors Event
                                  </v-btn>

                                  <v-btn large class="mr-2 mb-1" v-else-if="eventItem.type === 'CAGE WARRIORS'" style="cursor: default;"
                                        :color="dark ? 'grey darken-3' : 'grey'"
                                  >
                                    <v-img
                                      src="/img/logos/one_pride_logo.png"
                                      style="max-height: 25px; max-width: 25px;margin-right:5px"
                                    ></v-img>One Pride Event
                                  </v-btn>
                               </v-col>
                             </v-row>

                             <v-row v-if="item.featured">
                               <v-col cols="12" md="12" :align="'center'">
                                 <div class="text-center" :style="isMobileDevice ? 'padding-top: 20px; text-transform: uppercase;margin-bottom: -20px;' :'padding-top: 20px; margin-bottom: -20px; text-transform: uppercase'">
                                   <!-- <v-icon :color="dark ? '#fecd38' : '#FFA000'">mdi-star-box</v-icon> -->
                                   <span class="special-fight" :style="dark ? 'color: #F8DF8E;' : 'color:#FFA000;'">{{ lang[getLanguage].SPONSORED }}</span>
                                 </div>
                                 <!-- <v-img :src="item.sponsor_logo" max-height="100" max-width="200"></v-img> -->
                               </v-col>
                             </v-row>
                             
                             <!-- <div v-if="isMobileDevice" class="text-center" :style="isMobileDevice ? 'margin-top: 20px;' : ''">
                              <span class="font-weight-normal" :style="isMobileDevice ? 'font-size: 1rem;margin-top:20px' : 'font-size: 1rem;'">{{ lang[getLanguage].PLAYER_FAV }}</span>
                            </div> -->
 
                             <v-row class="pl-1 pr-1">
                                <v-col cols="4" md="4" :align="'top'" class="">
                                  <span class="font-weight-normal d-none" :style="isMobileDevice ? 'font-size: 0.8rem;opacity:0.7' : 'font-size: 1rem;opacity:0.7'">{{ makeDate(item.start) }}</span>
                                </v-col>
                                
                                <v-col cols="4" md="4" :align="'center'" >
                                  <v-layout column align-center >
                                    <!-- <span class="font-weight-normal pt-1" :style="isMobileDevice ? 'font-size: 0.8rem;opacity:0.7' : 'font-size: 1rem;opacity:0.7'">{{ makeTime(item.start) }} {{ isMobileDevice ? lang[getLanguage].LOCAL : lang[getLanguage].LOCAL_TIME }} </span> -->
                                    <v-chip :small="isMobileDevice"
                                      :class="index === 0 ? 'font-weight-bold ma-3' : 'ma-3'"
                                      :color="dark ? '#FFF' : '#CCC'"
                                      :style="dark ? 'color:#000' : 'color:#000'"
                                      class="d-none"
                                    >
                                    <!-- when main event show Main Event last-->
                                    <!-- {{ index === 0 && item.type === 'Main Card' ? 'Main Event' : item.type }}ss  -->
                                    </v-chip>
                                    <!-- <span class="font-weight-normal" :style="isMobileDevice ? 'font-size: 0.8rem;' : 'font-size: 1rem;'">{{ lang[getLanguage].PLAYER_FAV }}</span> -->
                                  </v-layout>
                                </v-col>
                                
                                <v-col cols="4" md="4" :align="'right'" class="" >
                                  <span class="font-weight-normal d-none" :style="isMobileDevice ? 'font-size: 0.8rem;opacity:0.7' : 'font-size: 1rem;opacity:0.7'">{{ eventItem.venue.name + ', ' + eventItem.venue.city_name }}</span>
                                </v-col>
                              </v-row>

                              <!-- ################# Competitors ##################### -->
                              <!-- <div  v-if="!isMobileDevice" class="text-center"><span class="font-weight-normal" :style="isMobileDevice ? 'font-size: 1rem;' : 'font-size: 1rem;margin-bottom: 40px'">{{ lang[getLanguage].PLAYER_FAV }}</span></div> -->
                              <v-layout pa-4 :style="isMobileDevice ? 'margin-top:-20px' : 'margin-top:-40px'" >
                                <v-row :align="'center'">
                                  <v-col cols="12" md="4">
                                    <v-list-item
                                    >
                                      <v-list-item-avatar :style="item.result_outcome === 1 ? 'cursor: pointer; width:90px;height:90px;border: 4px solid #4caf50;position:static' : 'cursor: pointer; width:90px;height:90px;border: 1px solid grey;position:static'"  @click="fighterRecordClicked(item.home.id)">
                                        <v-img 
                                          v-if="getFighterList.length > 0 && getFighterThumbnail(item.home.id) !== ''" 
                                          :src="getFighterThumbnail(item.home.id)"
                                          >
                                        </v-img>
                                        <v-icon v-else size="90" color="grey" >mdi-account-circle</v-icon>
                                        <v-btn
                                          v-if="item.result_outcome === 1"
                                          absolute
                                          dark
                                          fab
                                          bottom
                                          color="green"
                                          x-small
                                          style="margin-left:55px;margin-bottom:25px;"
                                        >
                                          <v-icon>mdi-check</v-icon>
                                        </v-btn>
                                      </v-list-item-avatar>
                                      <!-- <v-icon v-if="item.result_outcome === 1" style="margin-top:-63px;margin-left:-10px">mdi-seal-variant</v-icon> -->
                                    
                                      <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold text-white" :style="isMobileDevice ? 'font-size: 0.9rem;text-transform: uppercase; color: #fff; word-break: normal;text-wrap: wrap;padding-right:10px;flex-direction: column;' : 'font-size: 1.3rem;text-transform: uppercase; color: #fff'" v-html="makeLongName(item.home.name)"></v-list-item-title>
                                        <!-- <v-list-item-title style="margin-top:5px;font-size: 0.9rem" v-html="getWinLosDraw(item.home.id)"></v-list-item-title> -->
                                        <v-list-item-title style="margin-left:-15px;font-size: 0.9rem" >
                                          <!-- <v-btn class="d-none" text rounde @click="fighterRecordClicked(item.home.id)" :small="isMobileDevice">
                                            {{ lang[getLanguage].BROADCAST_FIGHTER_RECORD }}
                                            <v-icon>mdi-arrow-right-thin</v-icon>
                                          </v-btn> -->
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-col>

                                <v-col cols="12" md="4" :align="'center'" >
                                  <v-layout column align-center :style="isMobileDevice ? '' : ''">
                                    <v-btn text small @click="pointsAllocationClicked(eventItem)" class="d-none">
                                      <v-icon small style="margin-right:5px">mdi-information-outline</v-icon>{{ lang[getLanguage].BROADCAST_POINTS_INFO }}
                                    </v-btn>
                                    <table style="width:100%" >
                                      <tr>
                                        <th colspan="3" class="p-4">
                                          <!-- <span class="font-weight-light">{{ item.home.predictions_count + item.away.predictions_count === 0 ? lang[getLanguage].BROADCAST_404 : item.home.predictions_count + item.away.predictions_count === 1 ? lang[getLanguage].PREDICTIONS_FROM_ONE : lang[getLanguage].PREDICTIONS_FROM.replace("{x}", (item.home.predictions_count + item.away.predictions_count)) }}</span> -->
                                          <span class="" style="text-transform: uppercase;">{{lang[getLanguage].MFP_FAVOURITE}}</span>
                                        </th>
                                      </tr>
                                      <tr >
                                        <td style="width:48%">
                                          <v-progress-linear
                                            background-color="blue darken-3"
                                            color="grey"
                                            height="15"
                                            :value="100 - item.totalHome"
                                            rounded
                                          >
                                          <span style="font-size:10px;margin-left:0px" class="font-weight-bold">{{ item.totalHome }}%</span>
                                        </v-progress-linear>
                                      </td>
                                      <td style="width:4%">
                                      </td>
                                        <td style="width:48%">
                                          <v-progress-linear
                                            background-color="grey"
                                            color="blue darken-3"
                                            height="15"
                                            :value="item.totalAway"
                                            rounded
                                          ><span style="font-size:10px;margin-left:0px" class="font-weight-bold">{{ item.totalAway }}%</span>
                                        </v-progress-linear>
                                        </td>
                                          </tr>
                                    </table>
                                  </v-layout>
                                </v-col>

                                <v-col cols="12" md="4">
                                  <v-list-item
                                  > 
                                  <!-- <v-icon v-if="item.result_outcome === 1" style="margin-top:-63px;margin-left:100px">mdi-seal-variant</v-icon> -->
                                    <v-list-item-content style="text-align: end;">
                                      <v-list-item-title class="font-weight-bold" :style="isMobileDevice ? 'font-size: 0.9rem;text-transform: uppercase; color: #fff; word-break: normal;text-wrap: wrap;padding-left:10px;overflow-wrap: break-word;' : 'font-size: 1.3rem;text-transform: uppercase; color: #fff'" v-html="makeLongName(item.away.name)"></v-list-item-title>
                                      <!-- <v-list-item-title style="margin-top:5px;font-size: 0.9rem" v-html="getWinLosDraw(item.away.id)"></v-list-item-title> -->
                                      <v-list-item-title style="margin-right:-15px;font-size: 0.9rem" >
                                        <v-btn class="d-none" text rounded @click="fighterRecordClicked(item.away.id)" :small="isMobileDevice">
                                          <v-icon>mdi-arrow-left-thin</v-icon> 
                                          {{ lang[getLanguage].BROADCAST_FIGHTER_RECORD }}
                                        </v-btn>
                                      </v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-avatar :style="item.result_outcome === 2 ? 'cursor: pointer; width:90px;height:90px;border: 4px solid #4caf50;position:static' : 'cursor: pointer; width:90px;height:90px;border: 1px solid grey;position:static'"  @click="fighterRecordClicked(item.away.id)">
                                      <v-img v-if="getFighterList.length > 0 && getFighterThumbnail(item.away.id) !== ''" :src="getFighterThumbnail(item.away.id)"></v-img>
                                      <v-icon v-else size="90" color="grey">mdi-account-circle</v-icon>
                                      <v-btn
                                          v-if="item.result_outcome === 2"
                                          absolute
                                          dark
                                          fab
                                          bottom
                                          color="green"
                                          x-small
                                          style="margin-left:55px;margin-bottom:25px;"
                                        >
                                          <v-icon>mdi-check</v-icon>
                                        </v-btn>
                                    </v-list-item-avatar>
                                    
                                  </v-list-item>
                                  
                                </v-col>
                              </v-row>
                            </v-layout>

                            <!-- ################# Predict - Submission Open/Closed ##################### -->
                            <v-row v-if="getIsLoggedIn && (submissionOpen(eventItem) || submissionClosed(eventItem) && item.predictionSubmitted) && item.status !== 3 || (item.status !== 3 && item.results_processed > 0)" :style="isMobileDevice ? 'margin-left: 2px;margin-right: 2px;margin-bottom:5px' : 'margin-left: 30px;margin-right: 30px;margin-bottom:5px'" >
                              <v-col cols="12" md="4" :align="'center'">
                                <!-- <v-alert type="success"> -->
                                <div v-if="item.result_rounds === 0 && getIsLoggedIn" 
                                >
                                  <v-select v-if="item.result_outcome === 0 && getIsLoggedIn" :ref="'select1'"
                                    return-object 
                                    v-model="item.predictOutcome"
                                    :items="[{name: makeName(item.home.name), id: 1}, {name: makeName(item.away.name), id: 2}, {name: lang[getLanguage].PREDICTION_DRAW, id: 3}]"
                                    item-text="name"
                                    item-value="id"
                                    :label="item.predictOutcome === undefined ? lang[getLanguage].PREDICTION_OUTCOME.toUpperCase() : ''"
                                    :placeholder="lang[getLanguage].PREDICTION_OUTCOME.toUpperCase()"
                                    outlined
                                    dense
                                    :style="{
                                      border: item.predictOutcome !== undefined ? '2px solid green' : '',
                                      height: '42px',
                                      marginBottom: '10px'
                                    }"
                                    :disabled="item.predictionSubmitted && !item.changePrediction"
                                    @change="checkPredictionValid(item, index, eventIndex)"
                                    :menu-props="'auto, overflowY'"
                                    eager
                                  >
                                  </v-select>
                                </div>
                                <div v-else-if="item.result_outcome > 0">
                                  <div class="font-weight-bold">{{ lang[getLanguage].PREDICTION_OUTCOME }}</div>
                                  <div v-if="item.result_outcome === 1">{{ lang[getLanguage].PREDICTION_WINNER + ': ' + makeName(item.home.name).toUpperCase() }}</div>
                                  <div v-else-if="item.result_outcome === 2">{{ lang[getLanguage].PREDICTION_WINNER + ': ' + makeName(item.away.name).toUpperCase() }}</div>
                                  <div v-else-if="item.result_outcome === 3">{{lang[getLanguage].PREDICTION_DRAW }}</div>
                                  <template v-if="item.predictOutcome !== undefined">
                                    <div :class="typeof item.predictOutcome === undefined ? 'You made No Prediction' : item.predictOutcome.id === item.result_outcome ? 'green--text' : 'red--text'" v-if="getIsLoggedIn && getUserPredictions.length > 0">{{lang[getLanguage].YOUR_PREDICTION}}: {{ (item.predictOutcome.name).toUpperCase() }}</div>
                                  </template>
                                </div>
                              </v-col>

                              <v-col cols="12" md="4" :align="'center'">
                                <div v-if="item.result_rounds === 0 && getIsLoggedIn">
                                  <v-select v-if="item.result_method === 0 && getIsLoggedIn" :ref="'select2'"
                                    return-object
                                    v-model="item.predictMethod"
                                    :items="[{name: 'KO/TKO', id: 1}, {name: lang[getLanguage].PREDICTION_SUBMISSION, id: 2}, {name: lang[getLanguage].PREDICTION_DECISION, id: 3}]"
                                    item-text="name"
                                    item-value="id"
                                    :label="item.predictMethod === undefined ? lang[getLanguage].BROADCAST_HOW: ''"
                                    :placeholder="lang[getLanguage].BROADCAST_HOW"
                                    outlined
                                    eager
                                    dense
                                    :style="{
                                      border: item.predictMethod !== undefined ? '2px solid green' : '',
                                      height: '42px',
                                      marginBottom: '10px'
                                    }"
                                    :disabled="item.predictionSubmitted && !item.changePrediction ? true : typeof item.predictOutcome === 'undefined' ? false : item.changePrediction ? false : item.predictOutcome.id === 3"
                                    @change="checkPredictionValid(item, index, eventIndex)"
                                    :menu-props="'auto, overflowY'"
                                  >
                            
                                  </v-select>
                                </div>
                                <div v-else-if="item.result_method > 0">
                                  <div class="font-weight-bold">{{ lang[getLanguage].BROADCAST_HOW }}</div>
                                  <div v-if="item.result_method === 1">KO/TKO</div>
                                  <div v-else-if="item.result_method === 2">{{lang[getLanguage].PREDICTION_SUBMISSION}}</div>
                                  <div v-else-if="item.result_method === 3">{{lang[getLanguage].PREDICTION_DECISION}}</div>
                                  <template v-if="item.predictMethod !== undefined">
                                    <div :class="item.predictMethod.id === item.result_method ? 'green--text' : 'red--text'" v-if="getIsLoggedIn && getUserPredictions.length > 0">{{ lang[getLanguage].YOUR_PREDICTION }}: {{ updatePredictionLanguage(item.predictMethod.name) }}</div>
                                  </template>
                                </div>
                              </v-col>

                              <v-col cols="12" md="4" :align="'center'">
                                <div v-if="item.result_rounds === 0 && getIsLoggedIn">
                                  <v-select :ref="'select3'"
                                    return-object
                                    v-model="item.predictRounds"
                                    :items="makeRounds(item.scheduled_rounds)"
                                    item-text="name"
                                    item-value="id"
                                    :label="item.predictRounds === undefined ? lang[getLanguage].BROADCAST_WHICH_ROUND : ''"
                                    :placeholder="lang[getLanguage].BROADCAST_WHICH_ROUND"
                                    outlined
                                    :style="{
                                      border: item.predictRounds !== undefined ? '2px solid green' : '',
                                      height: '42px',
                                      marginBottom: '10px'
                                    }"
                                    dense
                                    :disabled="item.predictionSubmitted && !item.changePrediction ? true : typeof item.predictOutcome === 'undefined' ? false : item.changePrediction ? false : item.predictOutcome.id === 3"
                                    @change="checkPredictionValid(item, index, eventIndex)"
                                    eager
                                    :menu-props="'auto, overflowY'"
                                  ></v-select>
                                </div>
                                <div v-else-if="item.result_rounds > 0">
                                  <div class="font-weight-bold">{{ lang[getLanguage].BROADCAST_WHICH_ROUND }}</div>
                                  <div v-if="item.result_rounds === 1">{{ lang[getLanguage].PREDICTION_ROUNDS }} 1</div>
                                  <div v-else-if="item.result_rounds === 2">{{ lang[getLanguage].PREDICTION_ROUNDS }} 2</div>
                                  <div v-else-if="item.result_rounds === 3">{{ lang[getLanguage].PREDICTION_ROUNDS }} 3</div>
                                  <div v-else-if="item.result_rounds === 4">{{ lang[getLanguage].PREDICTION_ROUNDS }} 4</div>
                                  <div v-else-if="item.result_rounds === 5">{{ lang[getLanguage].PREDICTION_ROUNDS }} 5</div>
                                  <template v-if="item.predictRounds !== undefined">
                                    <div :class="item.predictRounds.id === item.result_rounds ? 'green--text' : 'red--text'" v-if="getIsLoggedIn && getUserPredictions.length > 0">{{ lang[getLanguage].YOUR_PREDICTION }}: {{ roundLanguage(item.predictRounds.id) }}</div>
                                  </template>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row :style="isMobileDevice ? 'margin-left: 2px;margin-right: 2px;' : 'margin-left: 30px;margin-right: 30px;margin-top: 0px'">
                              <v-col cols="12" md="12">
                                <v-alert
                                  v-if="item.predictionInvalid"
                                  dense
                                  outlined
                                  type="error"
                                  v-html="lang[getLanguage].PREDICTION_ALERT"
                                >
                                </v-alert> 
                              </v-col>
                            </v-row>

                            <v-row :style="isMobileDevice ? 'margin-left: 2px;margin-right: 2px;margin-top:-20px; margin-bottom: 5px' : 'margin-left: 30px;margin-right: 30px;margin-top: -15px; margin-bottom: 10px'" >

                              <v-col cols="12" md="12">

                                <!-- ############# "Prediction submission Open  #############-->
                                <v-layout style="" column v-if="getIsLoggedIn && item.predictionSubmitted && !item.changePrediction && item.status === 1 && item.results_processed === 0">
                                  <v-alert
                                    :style="isMobileDevice ? 'max-height:100px;padding-top:9px': 'max-height:46px;padding-top:9px'"
                                    color="green darken-2"
                                    class="ma-0"
                                    border="left"
                                    outlined
                                    type="success"
                                    elevation="2"
                                    icon="mdi-check"
                                  >
                                    <v-row align="center">
                                      <v-col class="grow">
                                        {{lang[getLanguage].INFO_PREDICTION}}
                                      </v-col>
                                      <v-col class="shrink" v-if="!isMobileDevice && submissionOpen(eventItem)">
                                        <v-btn 
                                          :color="dark ? '#C40721' : '#C40721'" 
                                          small 
                                          :style="dark ? 'color:#FFF; font-family: Verdana; font-weight: 800' : 'color:#FFF;'"
                                          @click="changePredictionClicked(eventIndex, index, eventItem)"
                                          >
                                          {{lang[getLanguage].CHANGE_PREDIDCTION}}
                                        </v-btn>
                                      </v-col>
                                  </v-row>
                                  </v-alert>
                                  <v-btn v-if="isMobileDevice && submissionOpen(eventItem)" class="mt-2"
                                    :color="dark ? '#C40721' : '##C40721'" 
                                    :style="dark ? 'color:#FFF font-family: Verdana; font-weight: 800' : 'color:#000'"
                                    @click="changePredictionClicked(eventIndex, index, eventItem)"
                                    >
                                    {{lang[getLanguage].CHANGE_PREDIDCTION}}
                                  </v-btn>
                                  <!-- <div v-if="submissionOpen(eventItem)" class="pt-2 text-center blue--text">{{ lang[getLanguage].PREDICTION_CLOSE_IN + makeDateTime(eventItem.submission_end) }} {{ timeDifference(Math.round(eventItem.submission_end / 1000)) }}</div> -->
                                </v-layout>
                               
                                <!--<v-btn v-else-if="getIsLoggedIn && item.predictionSubmitted && item.status === 1 && eventItem.results_processed === 0"
                                  large 
                                  color="green" 
                                  style="width:100%;
                                  color: #454545" 
                                  outlined
                                >
                                <v-icon>mdi-check</v-icon>
                                  Prediction Submitted
                                </v-btn> -->

                                <!-- ############# Submit Prediction Button Active  #####################-->
                                <!-- ############# Loading Predictions... When loading data #############-->
                                <v-layout column align-center
                                    v-else-if="getIsLoggedIn && submissionOpen(eventItem) && (!item.predictionSubmitted || item.changePrediction) && item.status === 1 && eventItem.results_processed === 0"> <!-- check predictions loaded -->
                                  <v-btn v-if="!showReloadPredictions"
                                    large 
                                    color="#C40721" 
                                    style="width:100%;text-transform: uppercase !important;
                                    color: ##FFFFFF; font-family: Verdana; font-weight: 800; font-size: 14px"
                                    :loading="item.loading" 
                                    :disabled="loadingPredictionData || showReloadPredictions"
                                    @click="submitPredictionsClicked(item, index, eventItem, eventIndex)"
                                  >
                                    {{ loadingPredictionData ? 'Loading your predictions...' : item.changePrediction ? lang[getLanguage].UPDATE_PREDIDCTION : lang[getLanguage].SUBMIT_PREDICTION }}
                                    <v-icon v-if="!loadingPredictionData">mdi-arrow-right-thin</v-icon>
                                  </v-btn>

                                  <v-btn v-else-if="showReloadPredictions"
                                    large 
                                    color="#C40721" 
                                    style="width:100%;text-transform: none !important;
                                    color: #fff; font-family: Verdana; font-weight: 800; font-size: 14px" 
                                    :loading="item.loading" 
                                    @click="refreshPredictions()"
                                  >
                                    {{ 'Could Not Load Predictions, please refresh' }}
                                    <v-icon v-if="!loadingPredictionData">mdi-arrow-right-thin</v-icon>
                                  </v-btn>
                                  <!-- <div v-if="submissionOpen(eventItem)" class="pt-2 blue--text">{{ lang[getLanguage].PREDICTION_CLOSE_IN + makeDateTime(eventItem.submission_end) }} ({{ timeDifference(Math.round(eventItem.submission_end / 1000)) }}) </div> -->
                                </v-layout>

                                <!-- ############# "Prediction submission will open on" notification  #############-->
                                <!-- <v-alert :class="isMobileDevice ? 'mt-2 d-none' : 'mt-6 d-none'" v-if="!submissionOpen(eventItem) && !submissionClosed(eventItem) && !checkFirstFightHasStarted(eventItem) && item.status === 1"
                                  dense
                                  
                                  type="info"
                                >
                                 <div>{{ lang[getLanguage].BROADCAST_COUNTDOWN + makeDateTime(eventItem.submission_start) }} ({{ timeDifference(Math.round(eventItem.submission_start / 1000)) }}) </div>
                                </v-alert> -->

                                <!-- ############# "Prediction submission closed on" notification  #############-->
                                <!-- <v-alert :class="isMobileDevice ? 'mt-2 d-none' : 'mt-6 d-none'" v-else-if="!submissionOpen(eventItem) && submissionClosed(eventItem) && !checkLastFightHasEnded(eventItem) && item.status === 1"
                                  dense
                                  
                                  type="info"
                                >
                                 <div>{{ lang[getLanguage].BROADCAST_CLOSE_ON + makeDateTime(eventItem.submission_end) }} ({{ timeDifferenceElapsed(Math.round(eventItem.submission_end / 1000)) }})</div>
                                </v-alert> -->

                                <!-- ############# Cancelled event fight  #############-->
                                <v-alert :class="isMobileDevice ? 'mt-2' : 'mt-6'" v-else-if="item.status === 3 && (item.totalHome > 0 || item.totalAway > 0)"
                                  dense
                                  
                                  type="error"
                                >
                                 <div>{{lang[getLanguage].BROADCAST_CANCELLED }}</div>
                                </v-alert>

                                <!-- ############# Login to make predictions  #############-->
                                <v-layout column align-center v-else-if="!getIsLoggedIn && submissionOpen(eventItem)">
                                  <v-btn
                                    large 
                                    color="#C40721" 
                                    style="width:100%;
                                    color: #fff;
                                    font-family: Verdana;
                                    font-weight: 800" 
                                    @click="loginClicked"
                                  >
                                    {{ lang[getLanguage].BROADCAST_LOGIN }}
                                    <v-icon>mdi-arrow-right-thin</v-icon>
                                  </v-btn>
                                  <!-- <div v-if="submissionOpen(eventItem)" class="pt-2 blue--text">{{ lang[getLanguage].PREDICTION_CLOSE_IN + makeDateTime(eventItem.submission_end) }} ({{ timeDifference(Math.round(eventItem.submission_end / 1000)) }})</div> -->
                                </v-layout>

                                <!-- ############# "Event has ended" notification  #############-->
                                <v-alert :class="isMobileDevice ? 'mt-2' : 'mt-6'" v-if="!submissionOpen(eventItem) && checkLastFightHasEnded(eventItem) && item.status === 1"
                                  dense
                                  
                                  type="success"
                                >
                                 <div>{{ eventItem.results_processed > 0 ? lang[getLanguage].BROADCAST_END : lang[getLanguage].BROADCAST_AVAILABLE_SOON }}</div>
                                </v-alert>

                              </v-col>
                            </v-row>
                            <!-- END -->

                          </v-card>
                          </template>
                          </div>
                        </template>
                  </v-tab-item>
                </v-tabs-items>

                <!-- getUserPredictions.length !== checkCancelledFights(filteredEventList[0]) || -->
                <v-row :class=" getUserPredictions.length !== checkCancelledFights(filteredEventList[0]) ? 'mx-auto' : 'mb-2 mx-auto'">
                  <v-col cols="12" class="text-center pa-8">
                    <v-btn color="#1465C0" 
                      class="rounded-xl mb-4 mb-md-0" 
                      large 
                      @click="showSocialDialog(true)" 
                      :block="$vuetify.breakpoint.mobile" 
                      :disabled="getUserPredictionsEmpty|| 
                        getUserPredictions.length !== checkCancelledFights(filteredEventList[0]) ||
                        checkLastFightHasEnded(getEventList[0]) ||
                        loadingPredictionData || 
                        showReloadPredictions"
                    >
                      <v-icon class="mr-2">mdi-share-variant-outline</v-icon>
                       {{lang[getLanguage].SHARE_BUTTON }}
                    </v-btn>

                    <span class="mx-md-2 my-md-0 my-2 mx-0"></span>

                    <v-btn color="#1465C0" class="rounded-xl" large 
                      @click="showDuelDialog(true)" 
                      :block="$vuetify.breakpoint.mobile" 
                      :disabled="getUserPredictionsEmpty || 
                        getUserPredictions.length !== checkCancelledFights(filteredEventList[0]) ||
                        loadingPredictionData || 
                        showReloadPredictions || 
                        getUser.email.split('@')[1] === 'mola.tv' ||
                        !submissionOpen(filteredEventList[0])">
                      <v-icon class="mr-2">mdi-trophy</v-icon>
                       {{ lang[getLanguage].DUEL_BUTTON}}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="mb-12" :style="isMobileDevice ? '' : 'margin-left:25%;margin-right:25%'"
                       v-if="getUserPredictions.length !== checkCancelledFights(filteredEventList[0]) && !checkLastFightHasEnded(getEventList[0])">
                  <v-col cols="12" class="text-center px-8" style="color:#EF5350">
                    {{ lang[getLanguage].DUEL_SHARE_INFO }}
                  </v-col>
                </v-row>

           </v-card>
        </v-layout>
         
        <!-- ############################### DIALOGS ##################### -->

        <DuelInvitationDialog
          :isOpen="duelDialog"
          @change="showDuelDialog"
          :isMobileDevice="isMobileDevice"
        ></DuelInvitationDialog>
        <SharePredictionDialog
          :isOpen="socialDialog"
          @change="showSocialDialog"
          :key="componentKey"
          :isMobileDevice="isMobileDevice"
        ></SharePredictionDialog>

        <v-dialog
          v-model="fighterRecordDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card
           :dark="dark"
          >
            <v-toolbar
              tile
              color="#F8DF8D"  
              style="color:#000"
            >
              <v-btn
                icon
                color="#000"
                @click="fighterRecordDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ lang[getLanguage].BROADCAST_FIGHTER_RECORD }}</v-toolbar-title>
            </v-toolbar>

            <v-row class="pa-4">
              
              <v-col cols="12" md="6" :align="isMobileDevice ? 'center' : 'right'">
                <v-list-item-avatar style="width:180px;height:180px;border: 1px solid grey">
                  <v-img v-if="getFighterThumbnail(selectedFighter.id) !== ''" :src="getFighterThumbnail(selectedFighter.id)"></v-img>
                  <v-icon v-else size="180" color="grey">mdi-account-circle</v-icon>
                </v-list-item-avatar>
              </v-col>

              <v-col cols="12" md="6" :align="isMobileDevice ? 'center' : 'left'">
                <v-card
                :dark="dark"
                flat
                class="pa-4"
                > 
                  <div style="text-transform: uppercase;" class="text-h4 font-weight-bold">{{ selectedFighter.name }}</div>
                  <div style="text-transform: uppercase;" class="text-h5 mb-2 font-weight-bold">{{ typeof selectedFighter.c_nickname === 'undefined' ? '' : this.lang[this.getLanguage].NICKNAME + selectedFighter.c_nickname }}</div>
                  <div style="text-transform: uppercase;" class="text-h7 mb-1 font-weight-bold">{{ this.lang[this.getLanguage].AGE + selectedFighter.c_age }}</div>
                  <div style="text-transform: uppercase;" class="text-h7 mb-1 font-weight-bold">{{ this.lang[this.getLanguage].DOB + [undefined, null, ''].includes(selectedFighter.c_dOB) ? '' : makeDate(selectedFighter.c_dOB) }}</div>
                  <!-- <div style="text-transform: uppercase;" class="text-h7 mb-1 font-weight-bold">{{ this.lang[this.getLanguage].DOB + typeof selectedFighter.c_dOB === 'undefined' ? '' : selectedFighter.c_dOB === '' ? '' : makeDate(selectedFighter.c_dOB) }}</div> -->
                  <div style="text-transform: uppercase;" class="text-h7 mb-1 font-weight-bold">{{ this.lang[this.getLanguage].GENDER + getGenderByLang(selectedFighter.gender) }}</div>
                </v-card>
              </v-col>

            </v-row>

            <v-row class="pa-4" :style="isMobileDevice ? '' : 'margin-right:10%;margin-left:10%'">
              
              <v-col cols="12" md="4" :align="'center'" >
                <v-card
                :dark="dark"
                flat
                style="border: grey solid 1px;"
                max-height="100px"
                class="pa-4"
                > 
                <div  class="text-h6 font-weight-bold">
                  <v-icon large style="margin-top:-4px">mdi-human-male-height</v-icon> {{ selectedFighter.c_height }}″
                </div>
                </v-card>
              </v-col>

              <v-col cols="12" md="4" :align="'center'" >
                <v-card
                :dark="dark"
                flat
                style="border: grey solid 1px;"
                max-height="100px"
                class="pa-4"
                > 
                <div class="text-h6 font-weight-bold">
                  <v-icon large style="margin-top:-4px">mdi-weight-pound</v-icon> {{ selectedFighter.c_weight }} lbs -- {{ selectedFighter.c_weightClass  }}
                </div>
                </v-card>
              </v-col>

              <v-col cols="12" md="4" :align="'center'" >
                <v-card
                :dark="dark"
                flat
                style="border: grey solid 1px;"
                max-height="100px"
                class="pa-4"
                > 
                <div  class="text-h6 font-weight-bold">
                  <v-icon large style="margin-top:-4px">mdi-earth</v-icon> {{ typeof selectedFighter.c_homeCity === 'undefined' ? '' : selectedFighter.c_homeCity + ', ' }} {{ typeof selectedFighter.c_homeCountry === 'undefined' ? '' : selectedFighter.c_homeCountry }}
                </div>
                </v-card>
              </v-col>

            </v-row>

            <v-row class="pa-4" :style="isMobileDevice ? '' : 'margin-right:10%;margin-left:10%'">
              
              <v-col cols="12" md="4" :align="'center'" >
                <v-card
                :dark="dark"
                flat
                style="border: grey solid 1px;"
                height="75px"
                class="pa-4"
                > 
                <div style="text-transform: uppercase;" class="text-h4 mb-4 font-weight-bold">{{  selectedFighter.c_recordWins }} {{ lang[getLanguage].WINS }}</div>
                </v-card>
              </v-col>

              <v-col cols="12" md="4" :align="'center'" >
                <v-card
                :dark="dark"
                flat
                style="border: grey solid 1px;"
                height="75px"
                class="pa-4"
                > 
                <div style="text-transform: uppercase;" class="text-h4 mb-4 font-weight-bold">{{  selectedFighter.c_recordLosses }} {{ lang[getLanguage].LOSSES }}</div>
                </v-card>
              </v-col>

              <v-col cols="12" md="4" :align="'center'" >
                <v-card
                :dark="dark"
                flat
                style="border: grey solid 1px;"
                height="75px"
                class="pa-4"
                > 
                <div style="text-transform: uppercase;" class="text-h4 mb-4 font-weight-bold">{{  selectedFighter.c_recordDraws }} {{ lang[getLanguage].DRAWS }}</div>
                </v-card>
              </v-col>

            </v-row>

            <v-row class="pa-4" :style="isMobileDevice ? '' : 'margin-right:10%;margin-left:10%'">

              <v-col cols="12" md="12" :align="'center'" >
                <v-card
                :dark="dark"
                flat
                style="border: grey solid 1px;"
                max-height="150px"
                class="pa-4"
                link
                @click="gotoLink(selectedFighter.c_uFCLink)"
                > 
                  <div class="text-h6 font-weight-bold" style="color: #F8DF8D">{{ lang[getLanguage].FIGHTER_SEE_MORE }}
                  </div>
                </v-card>
              </v-col>

          

            </v-row>
            
          </v-card>
        </v-dialog>
        
        <!-- Points Info Dialog-->
        <v-dialog
          v-model="pointsAllocationDialog"
          max-width="550"
        >
          <v-card :dark="!dark">
            <v-card-title class="text-h5" >
              {{ lang[getLanguage].BROADCAST_POINTS_ALLOCATION }}
            </v-card-title>
            
            <v-card-text>
              <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_CORRECT_OUTCOME }} {{ selectedEvent.points_outcome }}</div>
              <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_CORRECT_ROUND }} {{ selectedEvent.points_rounds }} {{ 'x'+selectedEvent.points_outcome }} {{ lang[getLanguage].BROADCAST_BONUS }}</div>
              <div style="font-size: 1rem;">{{lang[getLanguage].BROADCAST_CORRECT_METHOD}} {{ selectedEvent.points_method }} {{ 'x'+selectedEvent.points_outcome }} {{ lang[getLanguage].BROADCAST_BONUS }}</div>

              <div style="font-size: 1rem;" class="mb-2">{{lang[getLanguage].BROADCAST_BONUS_ALL}} + {{ selectedEvent.points_bonus }} {{ lang[getLanguage].BROADCAST_BONUS_POINTS }}</div>
                
              <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_MULTIPLIER_MAIN }} {{ selectedEvent.points_multi_main_card }}</div>
              <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_MULTIPLIER_PRELIMS }}{{ selectedEvent.points_multi_prelims }}</div>
              <div style="font-size: 1rem;">{{ lang[getLanguage].BROADCAST_MULTIPLIER_FEATURED }} {{ selectedEvent.points_multi_featured }}</div>
            </v-card-text>
            
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="pointsAllocationDialog = false"
              >
                {{ lang[getLanguage].CLOSE }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
        <v-dialog v-model="subscribeNowDialog" :width="!isMobileDevice ? '600px' : ''" :fullscreen="isMobileDevice">
            <v-card class="mx-auto" :dark="dark">
              <v-card-title v-if="!isMobileDevice"
                class="text-h6 font-weight-regular justify-center"
                style="background-color: #C40621; color: #fff"
              >
                <span>{{ lang[getLanguage].MY_PROFILE_NOT_MOLA_SUBSCRIBER }}</span>
              </v-card-title>
              <v-toolbar v-else style="background-color: #C40621; color: #fff">
                <v-btn icon :dark="dark" @click.native="subscribeNowDialog = false">
                        <v-icon>mdi-arrow-left</v-icon>
                      </v-btn>
                <v-card-title 
                  class="font-weight-regular"
                >
                  <span style="font-size:1rem"><span>You are not a Mola Subscriber</span></span>
                </v-card-title>
              </v-toolbar>
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-card-text>
                    <div class="pa-4 text-center">
                      <h3 class="text-h6 font-weight-light">
                        Join Our Mola Subscription Now! 
                      </h3>
                      <br>
                      <span>
                        In order to play the Prediction Games, you need have an active Mola Subscription.
                      </span>
                    </div>
                  </v-card-text>
                </v-window-item>
              </v-window>

              <v-divider></v-divider>

              <v-card-actions class="my-0 flex-column">
                <v-btn
                  style="color: #fff"
                  class="my-2"
                  v-show="step === 1"
                  color="#C40621"
                  block
                  large
                  depressed
                  @click.stop="gotoLink('https://mola.tv/subscription.html')"

                >
                  <span>{{ lang[getLanguage].MY_PROFILE_SUBSCRIBE_NOW }}</span>
                </v-btn>
                <v-btn
                  class="my-2 mx-0"
                  v-show="step === 1"
                  color="accent"
                  block
                  large
                  depressed
                  @click="subscribeNowDialog = false"
                >
                  <span>Cancel</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


        <v-dialog v-model="referralRewardMessage" persistent max-width="500">
          <v-card dark style="border-radius: 3px;border: #cdb15f solid 5px;">
            <v-card-title class="text-h5">{{  lang[getLanguage].CONGRATULATIONS }}</v-card-title>
            <v-card-text >
              <div style="font-size: 1rem;">{{ lang[getLanguage].FIRST_PREDICTION_TEXT1 }}</div>
              <div style="font-size: 1rem;" class="mt-2">{{ lang[getLanguage].FIRST_PREDICTION_TEXT2 }}</div>
 
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="#db0019"  @click="referralRewardMessage = false">{{ lang[getLanguage].CONTINUE_PREDICTING }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="refreshMandatoryDialog" persistent max-width="500">
          <v-card dark style="border-radius: 3px;border: #cdb15f solid 5px;">
            <v-card-title class="text-h5">{{ 'Silakan masuk kembali ke akun Anda' }}</v-card-title>
            <v-card-text >
              <div style="font-size: 1rem;">{{ 'Sesi login Anda telah berakhir!' }}</div>
              <div style="font-size: 1rem;" class="mt-2">{{ 'Silakan lanjutkan masuk.' }}</div>
 
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="#db0019"  @click="logoutUser">{{ lang[getLanguage].BROADCAST_LOGIN }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <!-- ############################### SNACKBARS ##################### -->

        
    
      </v-layout>
    </v-slide-y-transition>
    <PrizeSection v-show="false"></PrizeSection>  
  </div>
</template>

<script>
// @ is an alias to /src
// import { db } from '@/main'
import store from '@/store/index'
import dateformat from "dateformat"
import MolaMfpAPI from '@/clients/MolaMfpAPI'
import { db } from '@/main'
import { scroller } from 'vue-scrollto/src/scrollTo'
import PrizeSection from '../../components/prize_catalog/PrizeSection.vue'
import DuelInvitationDialog from '../../components/prediction/DuelInvitationDialog.vue'
import SharePredictionDialog from '../../components/prediction/SharePredictionDialog.vue'
export default {
  name: 'BroadcastSchedule',
  props: {
    isMobileDevice: Boolean,
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    isComp: Boolean
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: 'description', content: this.metaDescription },
        { property: 'og:title', content: this.metaTitle },
        // { property: 'og:og:image', content: this.metaImg },
        // { property: 'og:og:url', content: this.metaUrl },
        { property: 'og:site_name', content: 'Mola - Fight Predictor' },
        {property: 'og:type', content: 'website' },
        {name: 'robots', content: 'index,follow' }
      ]
    }
  },
  data: () => ({
    metaTitle: 'UFC Mola Fight Predictor',
    metaDescription: ' Check out the latest UFC schedule on Mola UFC Fight Predictor. Make your weekly predictions and never miss a fight!',
    localServerTime: 0,
    loading: false,
    loadingData: false,
    loadingPredictionData: false,
    showReloadSchedule: false,
    showReloadPredictions: false,
    fighterRecordDialog: false,
    pointsAllocationDialog: false,
    subscribeNowDialog: false,
    refreshMandatoryDialog: false,
    referralRewardMessage: false,
    step: 1,
    tab: 0,
    selectedItem: false,
    fightTypesArr: [],
    predictOutcome: '',
    predictMethod: '',
    predictRounds: '',
    activeEventsFighters: 0,
    selectedFighter: '',
    selectedEvent: '',
    eventType: '',
    combine: 'no',
    scrollcount: 0,
    duelDialog: false,
    socialDialog: false,
    imageSrc: '',
    componentKey: 0
  }), 
  computed: {
    getUser () {
      return this.$store.state.user
    },
    getIsMolaSubscriber () {
      return this.$store.state.user.mola_subscription
    },
    getIsLoggedIn () {
      return this.$store.state.user.isLoggedIn
    },
    getEventList () {
      return this.$store.state.fb.eventList
    },
    getEventListEmpty () {
      return this.$store.state.fb.eventListEmpty
    },
    getFighterList () {
      return this.$store.state.fb.fighterList
    },
    getFighterListEmpty () {
      return this.$store.state.fb.fighterListEmpty
    },
    getFightTypesArr () {
      return this.$store.state.fb.fightTypesArr
    },
    getUserPredictions () {
      return this.$store.state.fb.userPredictionsArr
    },
    getUserPredictionsEmpty () {
      return this.$store.state.fb.userPredictionsEmpty
    },
    getLiveLeaderBoardEventId () {
      return this.$store.state.liveLeaderBoardEventId
    },
    getReloadCount () {
      return this.$store.state.reloadCount
    },
    lang(){
      return this.$store.state.lang;
    },
    getLanguage(){
      return this.$store.state.current_language
    },
    filteredEventList () {
      console.log("filter", this.combine)
      if (this.combine === 'tabs' || this.combine === 'all') {
        return this.getEventList
      } else {
        return this.getEventList.filter(item => item.type === this.eventType)
      }
    }
  },
  watch: {
    getUserPredictions () {
      console.log('Changes in Predictions Detected')
      console.log(this.getUserPredictions) 
      // TODO
      for (var e in this.getEventList) {
        for (var i in this.getUserPredictions) {
          let findIndex = this.getEventList[e].fights.findIndex(item => item.id === this.getUserPredictions[i].fight_doc_id)
          // console.log('findIndex')
          // console.log(findIndex)
          if (findIndex > -1) {
            this.getEventList[e].fights[findIndex].predictionSubmitted = false
            this.getEventList[e].fights[findIndex].prediction_doc_id = this.getUserPredictions[i].doc_id
            this.getEventList[e].fights[findIndex].predictOutcome = {name: this.getUserPredictions[i].p_outcome_name, id: this.getUserPredictions[i].p_outcome}
            this.getEventList[e].fights[findIndex].predictMethod = {name: this.getUserPredictions[i].p_method_name, id: this.getUserPredictions[i].p_method}
            this.getEventList[e].fights[findIndex].predictRounds = {name: this.getUserPredictions[i].p_rounds_name, id: this.getUserPredictions[i].p_rounds}
            this.getEventList[e].fights[findIndex].predictionSubmitted = true
          } 
        }
        this.loadingPredictionData = false
      }
    },
    getReloadCount () {
      console.log('getReloadCount')
      this.reloadFightsLiveLeaderboard()
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.getTimeInterval)
  },
  components: {
    DuelInvitationDialog,
    SharePredictionDialog,
    PrizeSection,
  },
  created () {
    // Start
    window.addEventListener('scroll', this.handleScroll);
    console.log('getIsLoggedIn')
    console.log(this.getIsLoggedIn)
    this.init()
    // this.loadingData = false

    // if staging then
    // metaTitle = ''
    // metaDesc = ''
  },
  methods: {
    async handleScroll (e){
      // console.log(e)
      if (this.scrollcount < 10) {
        this.scrollcount++
        return
      } 
      e.preventDefault()
      if(this.$refs.select1) {
        // console.log(this.$refs.select1)
        e.stopPropagation()
        setTimeout(()=>{
          for (var m in this.$refs.select1) {
            this.$refs.select1[m].isMenuActive = false;
            this.$refs.select1[m].isFocused = false;
          }
          this.scrollcount = 0
        }, 10)
      }
      if(this.$refs.select2) {
        //console.log(this.$refs.select2)
        e.stopPropagation()
        setTimeout(()=>{
          for (var m in this.$refs.select2) {
            this.$refs.select2[m].isMenuActive = false;
            this.$refs.select2[m].isFocused = false;
          }
          this.scrollcount = 0
        }, 10)
      }
      if(this.$refs.select3) {
        //console.log(this.$refs.select3)
        e.stopPropagation()
        setTimeout(()=>{
          for (var m in this.$refs.select3) {
            this.$refs.select3[m].isMenuActive = false;
            this.$refs.select3[m].isFocused = false;
          }
          this.scrollcount = 0
        }, 10)
      }
    },
    init () {
      // this.scrollToTop()
      this.loadBroadcastSchedule()
      // this.changeUserPrediction();
      this.getServerTime()
      // setTimeout(() => {
      //   this.changeUserPrediction();
      // }, 5000);
      this.getTimeInterval = setInterval(() => {
        this.getServerTime()
      }, 300000)
    },
    scrollToTop () {
      const firstScrollTo = scroller();
      setTimeout(() => {
        firstScrollTo('#broadcastschedule', {offset: -64});
      }, 200);
    },
    loginClicked () {
      // TO DO
      this.$emit("loginClicked")
    },
    eventTypeSwitch (type) {
      this.eventType = type
    },
    checkType (type) {
      return this.getEventList.findIndex(item => item.type === type) > -1
    },
    liveLeaderboardClicked (event) {
      console.log(event.doc_id)
      console.log(event.name)
      this.$store.commit('setLiveLeaderbordEventId', event.doc_id)
      // this.$store.commit('setLiveLeaderbordEvent', event)
      this.$emit("liveLeaderboardClicked")
    },
    checkCombine(){
      if(this.getEventList.length > 0){
        const combines = this.getEventList.map(item => item.combine);
        console.log("combines",combines)
        if(combines.includes(undefined)){
          return "no";
        }
        if(combines.includes("all")){
          return "all";
        }
        if(combines.includes("tabs")){
          return "tabs";
        }
        if(combines.includes("no")){
          return "no";
        }
      }
      return "no";
    },
    loadBroadcastSchedule () {
      console.log('loadBroadcastSchedule')
      if (this.getEventList.length > 0) {
          console.log("event list", this.getEventList[0])

        let checkTime = Math.round(new Date().getTime() / 1000)
        if (this.getEventList[0]?.checkTime > Math.round(checkTime)) {
          console.log('No reload needed')
          console.log('this.getUserPredictions.length')
          console.log(this.getUserPredictions.length)
          console.log(this.getUserPredictions)
          this.combine = this.getEventList[0].combine
          if (this.getEventList.length === 1) {
            this.eventType = this.getEventList[0].type
          }
          if(this.getIsLoggedIn && this.getUserPredictions.length === 0) {
            let cnt = 1
            this.loadingPredictionData = true
            this.loadingData = false
            for (var e in this.getEventList) {
              this.getUserPredictionsForEvent(this.getEventList[e].doc_id, cnt)
              cnt += 1
            }
            this.changeUserPrediction();
          } else {
            this.loadingPredictionData = false
          }
          return
        }
      }
        this.loadingData = true
        this.showReloadSchedule = false
        let dispatchObj = {
          limit: 20 // this.limit
        }
      this.$store.dispatch('getEventList', dispatchObj)
        .then(() => {
          var count = 0
          var countE = 0
          var interval = setInterval(() => {
            let typesArr = []
            if (this.getEventList.length > 0 && countE < 20) {
              let fightersArray = []
              for (var i in this.getEventList) {
                console.log("Count i", count, i);
                for (var j in this.getEventList[i].fights) {
                  console.log("Count j", count, j);
                  // if not in Firebase yet >> Load from API >> check by fighter.id sr:competitor:981227
                  var fightersHome = this.getEventList[i].fights[j].home
                  this.getEventList[i].fights[j].home.doc_id = this.getEventList[i].doc_id
                  var fightersAway = this.getEventList[i].fights[j].away
                  this.getEventList[i].fights[j].away.doc_id = this.getEventList[i].doc_id

                  fightersArray.push(fightersHome)
                  fightersArray.push(fightersAway)

                  // this.combine = this.checkCombine();
                  console.log('####### this.combine #########')
                  console.log(this.combine)

                  typesArr.push(this.getEventList[i].fights[j].type)

                  // console.log('Home: ' + fighterHomeId)
                  // console.log('Away: ' + fighterAwayId)

                  // this.getFighterInfoAPI(this.getEventList[i].fights[j].home, this.getEventList[i].doc_id)
                  // this.getFighterInfoAPI(this.getEventList[i].fights[j].away, this.getEventList[i].doc_id)
                }
                console.log('############ this.getEventList ############')
                console.log(this.getEventList)
                console.log('############ count ############')
                console.log('Count: ' + count)
                count+=1
                this.checkFighterInFirebase(fightersArray, this.getEventList[i].doc_id, count)
                this.getUserPredictionsForEvent(this.getEventList[i].doc_id, count)
              }
              if (this.getEventList.length === 1) {
                this.eventType = this.getEventList[0].type
              }
              typesArr = [...new Set(typesArr)]
              store.commit('setFightTypesArr', typesArr)
              console.log('############### fightersArray #############')
              console.log(fightersArray)
              console.log("Combine 1", this.combine)
              this.combine = this.checkCombine();
              console.log("Combine 2", this.combine)
              this.loadingData = false
              clearInterval(interval)
            } else if (countE >= 20) {
              this.loadingData = false
              this.showReloadSchedule = true
              console.log('The counter has stopped')
              clearInterval(interval)
            } else if (this.getEventListEmpty) {
              this.loadingData = false
            } else {
              countE += 1
              console.log('############## this.getEventListEmpty ###############')
              console.log(this.getEventListEmpty)
            }
            // typesArr = [...new Set(typesArr)]
            // store.commit('setFightTypesArr', typesArr)
            // this.loadingData = false
            // console.log('Counter is: ' + count)
            // console.log('this.getFightTypesArr')
            // console.log(this.getFightTypesArr)
          }, 2000)
        })
        .catch(error => {
          console.log(error)
          this.loadingData = false
        })
      },
      checkFighterInFirebase (fArray, eventDocId, count) {
        if (this.getFighterList.length === fArray.length) {
          let checkTime = Math.round(new Date().getTime() / 1000)
          if (this.getFighterList[0]?.checkTime > Math.round(checkTime)) {
            console.log('No fighter reload needed')
            return
          }
        }
        let dispatchObj = {
          event_doc_id: eventDocId, // Event ID to lookup with 1 call in firebase
          current_arr: this.getFighterList,
          loadMore: true,
        }
        console.log(dispatchObj)
        this.$store.dispatch('getFighterList', dispatchObj)
          .then(() => {
            var countF = 0
            var interval = setInterval(() => {
              if (this.getFighterList.length >= 0 && this.getEventList.length === count && countF < 20) {
                this.loadingData = false
                clearInterval(interval)
                for (var i in fArray) {
                  // console.log(fArray[i].id)
                  var inArr = this.getFighterList.findIndex(item => item.id === fArray[i].id) > -1
                  // console.log('inArr:' + inArr)
                  if (!inArr && this.getEventList.length === count){
                    // ############ Disable Temporary ############
                    // this.getFighterInfoAPI(fArray[i])
                    // compare the fist name and last nam,e with the result set
                    // if no match search only last name
                  }
                }
                countF += 1
              } else if (countF >= 20) {
                this.loadingData = false
                // this.showReloadPredictions = true
                console.log('The counter has stopped')
                clearInterval(interval)
              } else if (this.getFighterListEmpty) {
                this.loadingData = false
                // clearInterval(interval)
              } else {
                countF += 1
                // this.loadingData = false
              }
            }, 2000)
            console.log('Fighters List from Firebase: ' + eventDocId)
            console.log(this.getFighterList)
            //check if fArray[i].id all in this.getFighterList[i].id >> lookup in getFighterInfoAPI(fArray[i].name, eventId)
            // if not then getFighterInfoAPI()
          })
          .catch(error => {
            console.log(error)
            this.loadingData = false
          })
      },
      reloadFightsLiveLeaderboard () {
        let event_doc_id =  this.getLiveLeaderBoardEventId
        console.log('######### this.getLiveLeaderBoardEventId #############')
        console.log(event_doc_id)
        console.log('######### this.getEventList #############')
        console.log(this.getEventList)
        let index = this.getEventList.findIndex(item => item.doc_id === event_doc_id)
        console.log('index reloadFightsLiveLeaderboard')
        console.log(index)
        let query = db
          .collection('events')
          .doc(event_doc_id)
          .collection('fights')
          .orderBy('start', 'asc');
          query.get().then(
          (snapshot) => {
              console.log(
              `Received query snapshot of size ${snapshot.size}`
              );
              if (snapshot.empty) {
              console.log('No matching documents.');
              this.loadingData = false;
              return;
              }
              // console.log(index)
              this.getEventList[index].fights = [];
              snapshot.forEach((doc) => {
                // console.log(doc.id, '=>', doc.data())
                var obj = doc.data();
                obj.id = doc.id;
                obj.predictionInvalid = false;
                obj.predictionSubmitted = false;
                obj.changePrediction = false;
                obj.loading = false;
                // calculate predictions
                let homePredictions = doc.data().home.predictions_count; // !! Update SOON
                let awayPredictions = doc.data().away.predictions_count;
                let totalHome =
                    homePredictions === 0
                    ? 0
                    : Math.round(
                        (100 / (homePredictions + awayPredictions)) *
                            homePredictions
                        );
                let totalAway =
                    awayPredictions === 0
                    ? 0
                    : Math.round(
                        (100 / (homePredictions + awayPredictions)) *
                            awayPredictions
                        );
                obj.totalHome = totalHome;
                obj.totalAway = totalAway;
                // console.log(totalHome, totalAway)
                this.getEventList[index].fights.push(obj);
              });
              this.getEventList[index].fights.sort(function (a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
                return new Date(b.start) - new Date(a.start);
              });
              for (var i in this.getUserPredictions) {
                for (var e in this.getEventList) {
                  let findIndex = this.getEventList[e].fights.findIndex(item => item.id === this.getUserPredictions[i].fight_doc_id)
                  // console.log('findIndex')
                  // console.log(findIndex)
                  if (findIndex > -1) {
                    this.getEventList[e].fights[findIndex].predictionSubmitted = true
                    this.getEventList[e].fights[findIndex].prediction_doc_id = this.getUserPredictions[i].doc_id
                    this.getEventList[e].fights[findIndex].predictOutcome = {name: this.getUserPredictions[i].p_outcome_name, id: this.getUserPredictions[i].p_outcome}
                    this.getEventList[e].fights[findIndex].predictMethod = {name: this.getUserPredictions[i].p_method_name, id: this.getUserPredictions[i].p_method}
                    this.getEventList[e].fights[findIndex].predictRounds = {name: this.getUserPredictions[i].p_rounds_name, id: this.getUserPredictions[i].p_rounds}
                  } 
                }
              }
          },
          (err) => {
              console.log(`Encountered error: ${err}`);
          }
        )
      },
      getFighterInfoAPI (fighter) {
        let name = this.makeName(fighter.name)
        Promise.resolve(MolaMfpAPI.getFighterDetailsMain(name))
          .then(result => {
            console.log(result) // result.data
            // result.data.response.results[0].data
            // check if result.data.response.results[0].data.name === API Call fighter.name
            // insert into to Firebase or Update.....
            let dispatchObj = result.data.response.results[0].data
            dispatchObj.id = fighter.id
            dispatchObj.event_id = fighter.doc_id
            this.$store.dispatch('insertFighter', dispatchObj)
              .then(() => {
                console.log('Fighter Added to Firebase!')
              })
              .catch(error => {
                console.log(error)
                this.loadingData = false
              }) 
          })
          .catch(err => {
            this.loading = false
            console.log('Error getting Fighter info.', err)
            // show friendly error in user screen 
          })
      },
      getUserPredictionsForEvent (eventDocId, count) {
      console.log('################## getUserPredictionsForEvent ##################')
        // set predictionSubmitted in events.fight array to true if exists
       /* if (this.getUserPredictions.length > 0) {
          let checkTime = Math.round(new Date().getTime() / 1000)
          if (this.getUserPredictions[0].checkTime > Math.round(checkTime)) {
            console.log('No fighter reload needed')
            return
          }
        } */
        this.loadingPredictionData = true
        this.showReloadPredictions = false
        let dispatchObj = {
          event_doc_id: eventDocId,
          uid: this.getUser.uid,
          current_arr: this.getUserPredictions
        }
        console.log('###################### !!!!!!!!!!!!!!! #####################')
        console.log(this.getUserPredictions.length)
        if (this.getUserPredictions.length > 0) return
        this.$store.dispatch('getUserPredictions', dispatchObj)
          .then(() => {
            var countP = 0
            console.log('this.getEventList.length === count')
            console.log(this.getEventList.length, count)
            var interval = setInterval(() => {
              if (this.getUserPredictions.length > 0 && countP < 20) {
                clearInterval(interval)
                console.log('######## findInFights #################')
                console.log(this.getEventList)
                console.log(this.getUserPredictions)

                for (var i in this.getUserPredictions) {
                  for (var e in this.getEventList) {
                    let findIndex = this.getEventList[e].fights.findIndex(item => item.id === this.getUserPredictions[i].fight_doc_id)
                    // console.log('findIndex')
                    // console.log(findIndex)
                    if (findIndex > -1) {
                      this.getEventList[e].fights[findIndex].predictionSubmitted = true
                      this.getEventList[e].fights[findIndex].prediction_doc_id = this.getUserPredictions[i].doc_id
                      this.getEventList[e].fights[findIndex].predictOutcome = {name: this.getUserPredictions[i].p_outcome_name, id: this.getUserPredictions[i].p_outcome}
                      this.getEventList[e].fights[findIndex].predictMethod = {name: this.getUserPredictions[i].p_method_name, id: this.getUserPredictions[i].p_method}
                      this.getEventList[e].fights[findIndex].predictRounds = {name: this.getUserPredictions[i].p_rounds_name, id: this.getUserPredictions[i].p_rounds}
                    }
                  }
                }
                this.loadingPredictionData = false
                countP += 1
              } else if (countP >= 20) {
                this.loadingPredictionData = false
                this.showReloadPredictions = true
                console.log('The counter has stopped')
                clearInterval(interval)
              } else if (this.getUserPredictionsEmpty) {
                this.loadingPredictionData = false
                clearInterval(interval)
              } else {
                console.log('The counter is running', countP)
                countP += 1
                if (countP === 5 && this.loadingPredictionData && this.getIsLoggedIn) {
                  clearInterval(interval)
                  if (this.$router.currentRoute.path === '/home') {
                    this.$router.push('/')
                  } else{
                    this.$router.push('/home')
                  }
                  // this.$router.push('/')
                } else if (!this.getIsLoggedIn) {
                    clearInterval(interval)
                }
                // this.loadingData = false
              }
            }, 2000)
            console.log('User Predictions from Firebase: ' + eventDocId)
            console.log(this.getUserPredictions)
          })
          .catch(error => {
            console.log(error)
            this.loadingPredictionData = false
          })
      },
      saveSettingsData(obj) {
        db.collection('users')
          .doc(this.getUser.uid)
          .update(obj)
          .then(() => {
            console.log('Referral code User Account in bucket updated to ""');
                 ///Snackbar That confirms
            this.$store.commit('setState', obj);
            })
            .catch((error) => {
              console.log(error);
            });
          },
       async rewardUsersForReferral (rewardObj) {

          await this.$store.dispatch('referralRewardToMolaPoints', rewardObj)
          let obj = {
            referral_code: ''
          }
        this.saveSettingsData(obj);
        this.referralRewardMessage = true
        this.$store.dispatch('getMolaPointsLedger', {uid: this.getUser.uid})
      },
      submitPredictionsClicked (item, index, eventItem, eventIndex) {
        console.log('item')
        console.log(item)

        console.log('fight index')
        console.log(index)

        console.log('eventItem')
        console.log(eventItem)

        console.log('eventIndex')
        console.log(eventIndex)
        
        if ([null, undefined, ''].includes(this.getUser.uid) || [null, undefined, ''].includes(this.getUser.name)){
          this.refreshMandatoryDialog = true;
          return
        }

        let fighterId;
        if(item.predictOutcome?.id === 1) {
          fighterId = item.home.id
        } else if (item.predictOutcome?.id === 2) {
          fighterId = item.away.id
        } else {
          fighterId = ''
        }

        if(this.getIsMolaSubscriber < 0){
          this.subscribeNowDialog = true;
          return
        }

        // ########## Check Submission Still Open
        if (this.submissionClosed(eventItem)) {
          this.filteredEventList[eventIndex].fights[index].predictionInvalid = true
          this.filteredEventList[eventIndex].fights[index].predictionInvalid = false
          // this.getEventList[eventIndex].fights[index].predictionInvalid = true
          return
        }
        // Check if Edit Prediction
        if (this.filteredEventList[eventIndex].fights[index].changePrediction) {
          // ############## CHANGE Prediction  ##############
          console.log('Change Prediction Clicked')
          this.filteredEventList[eventIndex].fights[index].loading = true
          let updateObj = {}
          updateObj.id = item.prediction_doc_id
          // updateObj.mola_sub_type = this.getIsMolaSubscriber
          updateObj.p_outcome = item.predictOutcome.id
          updateObj.p_outcome_name = item.predictOutcome.name
          updateObj.p_method = item.predictMethod.id
          updateObj.p_method_name = item.predictMethod.name
          updateObj.p_rounds = item.predictRounds.id
          updateObj.p_rounds_name = item.predictRounds.name
          updateObj.modified = new Date().getTime()
          updateObj.fighter_id = fighterId
          console.log(updateObj)
          // Insert database first then objects
          console.log('item.prediction_doc_id')
          console.log(item.prediction_doc_id)
          this.$store.dispatch('updatePrediction', updateObj)
            .then(() => {
              console.log('Prediction Updated in Firebase!')

              this.filteredEventList[eventIndex].fights[index].predictionSubmitted = true
              this.filteredEventList[eventIndex].fights[index].changePrediction = false

              this.filteredEventList[eventIndex].fights[index].loading = false
              // Update realtime objects first
              
            })
            .catch(error => {
              console.log(error)
              this.loadingData = false
            })
        } else {
          // ############## Insert NEW Prediction  ##############
          // item.pid = eventItem.doc_id
          this.filteredEventList[eventIndex].fights[index].predictionInvalid = false
          console.log(item)
          console.log(typeof item.predictOutcome)
          console.log(this.filteredEventList)

          if (typeof item.predictOutcome === 'undefined' || typeof item.predictMethod === 'undefined' || typeof item.predictRounds === 'undefined') {
            this.filteredEventList[eventIndex].fights[index].predictionInvalid = true
            this.filteredEventList[eventIndex].fights[index].changePrediction = false
          } else if (item.predictOutcome === null || item.predictMethod === null || item.predictRounds === null) {
            this.filteredEventList[eventIndex].fights[index].predictionInvalid = true
            this.filteredEventList[eventIndex].fights[index].changePrediction = false
            return
          } else {
            this.filteredEventList[eventIndex].fights[index].loading = true
            this.filteredEventList[eventIndex].fights[index].changePrediction = false
            let postkey = db.collection('predictions').doc()
            let dispatchObj = {}
            dispatchObj.id = postkey.id
            dispatchObj.uid = this.getUser.uid
            dispatchObj.username = this.getUser.name
            dispatchObj.full_name = this.getUser.full_name
            dispatchObj.email = this.getUser.email
            dispatchObj.mola_sub_type = this.getIsMolaSubscriber
            dispatchObj.event_id = eventItem.id
            dispatchObj.event_doc_id = eventItem.doc_id
            dispatchObj.fight_doc_id = item.id
            dispatchObj.fight_id = item.fid
            dispatchObj.p_outcome = item.predictOutcome.id
            dispatchObj.p_outcome_name = item.predictOutcome.name
            dispatchObj.p_method = item.predictMethod.id
            dispatchObj.p_method_name = item.predictMethod.name
            dispatchObj.p_rounds = item.predictRounds.id
            dispatchObj.p_rounds_name = item.predictRounds.name
            dispatchObj.p_game_type = item.type
            dispatchObj.p_game_name = this.makeName(item.home.name) + ' Vs. ' + this.makeName(item.away.name)
            dispatchObj.created = new Date().getTime()
            dispatchObj.points_won = 0
            dispatchObj.points_won_outcome = 0
            dispatchObj.points_won_method = 0
            dispatchObj.points_won_rounds = 0
            dispatchObj.results_processed = 0
            dispatchObj.fighter_id = fighterId
            console.log(dispatchObj)

            // Insert database first then objects
            this.$store.dispatch('insertPrediction', dispatchObj)
              .then(() => {
                console.log('Prediction Added to Firebase!')

                this.getEventList[eventIndex].fights[index].predictionSubmitted = true

                // Update realtime objects first
                if (item.predictOutcome.id === 1) {
                  this.filteredEventList[eventIndex].fights[index].home.predictions_count += 1
                  this.filteredEventList[eventIndex].fights[index].changePrediction = false
                  this.filteredEventList[eventIndex].fights[index].loading = false
                  this.filteredEventList[eventIndex].fights[index].prediction_doc_id = postkey.id
                } else if (item.predictOutcome.id === 2) {
                  this.filteredEventList[eventIndex].fights[index].away.predictions_count += 1
                  this.filteredEventList[eventIndex].fights[index].changePrediction = false
                  this.filteredEventList[eventIndex].fights[index].loading = false
                  this.filteredEventList[eventIndex].fights[index].prediction_doc_id = postkey.id
                } else if (item.predictOutcome.id === 3) {
                  this.filteredEventList[eventIndex].fights[index].home.predictions_count += 1
                  this.filteredEventList[eventIndex].fights[index].away.predictions_count += 1
                  this.filteredEventList[eventIndex].fights[index].changePrediction = false
                  this.filteredEventList[eventIndex].fights[index].loading = false
                  this.filteredEventList[eventIndex].fights[index].prediction_doc_id = postkey.id
                }

                // Update Database
                let updateObj = {}
                updateObj.postkeyEvent = eventItem.doc_id
                updateObj.postkeyFight = item.id
                updateObj.home = this.filteredEventList[eventIndex].fights[index].home
                updateObj.away = this.filteredEventList[eventIndex].fights[index].away
                // calculate predictions and update realtime objects
                let homePredictions = this.filteredEventList[eventIndex].fights[index].home.predictions_count // !! Update SOON
                let awayPredictions = this.filteredEventList[eventIndex].fights[index].away.predictions_count
                let totalHome = homePredictions === 0 ? 0 : Math.round((100 / (homePredictions + awayPredictions)) * homePredictions)
                let totalAway = awayPredictions === 0 ? 0 : Math.round((100 / (homePredictions + awayPredictions)) * awayPredictions)
                this.filteredEventList[eventIndex].fights[index].totalHome = totalHome
                this.filteredEventList[eventIndex].fights[index].totalAway = totalAway

                this.$store.dispatch('updateEventPredictionPercent', updateObj)
                  .then(() => {
                    console.log('Prediction Count updated in Firebase!')
                    this.loadingData = false
                  })
                  .catch(error => {
                    console.log(error)
                    this.loadingData = false
                  })

                  console.log(this.getUser.referral_code)
                  if(this.getUser.referral_code !== '') { 
                    console.log(this.getUser.referral_code)
                    let rewardObj = {
                      uid_receiver: this.getUser.uid,
                      uid_receiver_name: this.getUser.name,
                      referral_code: this.getUser.referral_code
                    }
                      this.rewardUsersForReferral(rewardObj)
                  }
              })
              .catch(error => {
                console.log(error)
                this.loadingData = false
              })
            }
        }
      },
      changePredictionClicked (eventIndex, index, eventItem) {
        // console.log(this.getEventList[eventIndex].fights[index])
        if (this.loadingPredictionData) return
        // ########## Check Submission Still Open
        if (this.submissionClosed(eventItem)) {
          this.filteredEventList[eventIndex].fights[index].changePrediction = true
          this.filteredEventList[eventIndex].fights[index].changePrediction = false
          return
        }
        this.filteredEventList[eventIndex].fights[index].changePrediction = true
      },
      checkPredictionValid (item, index, eventIndex) {
        console.log('item.predictOutcome')
        console.log(item.predictOutcome)
        this.selectedIndexSelect = index
        if (typeof item.predictOutcome === 'undefined') {
          return
        } else if (item.predictOutcome.id === 3) {
          this.filteredEventList[eventIndex].fights[index].predictMethod = {id: 3, name: 'Keputusan'}
          this.filteredEventList[eventIndex].fights[index].predictRounds = {id: item.scheduled_rounds, name: 'Ronde ' + item.scheduled_rounds }
          return
        } else if (item.predictOutcome.id !== 3) {
          console.log('outcome is not 3')
          console.log(item.predictMethod)
          console.log(item.predictRounds)
          if (item.predictMethod === null || item.predictRounds === null) {
            this.filteredEventList[eventIndex].fights[index].predictMethod = {}
            this.filteredEventList[eventIndex].fights[index].predictRounds = {}
            this.filteredEventList[eventIndex].fights[index].predictMethod = item.predictMethod
            this.filteredEventList[eventIndex].fights[index].predictRounds = item.predictRounds
          }
        }
        if (typeof item.predictOutcome === 'undefined' || typeof item.predictMethod === 'undefined' || typeof item.predictRounds === 'undefined') {
          return
        } else if (item.predictOutcome === null || item.predictMethod === null || item.predictRounds === null) {
          this.filteredEventList[eventIndex].fights[index].predictionInvalid = true
          this.selectedItem = false
          return
        } else {
          this.filteredEventList[eventIndex].fights[index].predictionInvalid = false
          this.selectedItem = true
        }
      },
      refreshPredictions () {
        window.location.reload();
      },
      pointsAllocationClicked (item) {
        this.selectedEvent = item
        this.pointsAllocationDialog = true
      },
      makeDate(date){
        return dateformat(new Date(date), 'dd mmm, yyyy')
      },
      makeDateV2(date) {
        console.log(date)
        let newDateFormat;
        try{
          newDateFormat = dateformat(new Date(date), 'dd mmm, yyyy')
        }catch(err){
          console.log(err)
        }
        return newDateFormat;
      },
      makeTime (date) {
        return dateformat(new Date(date), 'HH:MM')
      },
      makeDateTime (date) {
        return dateformat(new Date(date), 'dd mmm, yyyy HH:MM')
      },
      makeName (name) {
        // console.log('name.indexOf(',') ===  -1')
        // console.log(name.indexOf(',') ===  -1)
        if (name.indexOf(',') ===  -1) return name
        name.replace(' ', '')
        // console.log(name.replace(' ', '').split(',')[1] + ' ' + name.split(',')[0])
        return name.replace(' ', '').split(',')[1] + ' ' + name.split(',')[0]
      },
      makeLongName (name) {
        // console.log('longName check')
        // console.log(name)
        let longName
        let tempName
        if (name.indexOf(',') ===  -1) {
          longName = name.split(' ')
          // console.log('longName 01 no comma')
          // console.log(longName)
          if (longName.length > 2) {
            return longName[0] + ' ' + longName[1] + '<br>' + longName[2]
          } else {
            return longName[0] + '<br>' + longName[1]
          }
        } else {
          tempName = name.replace(', ', ' ') 
          // console.log('########## tempName ############')
          // console.log(tempName)
          longName = tempName.split(' ')
          if (longName.length > 2) {
            // console.log('longName 01 no comma')
            // console.log(longName)
            return longName[2] + ' ' + longName[0] + '<br>' + longName[1]
          } else {
            return longName[1] + '<br>' + longName[0]
          }
        }
      },
      getFighterThumbnail (id) {
        // console.log('Fighter ID: ' + id)
        // console.log('getFighterThumbnail:' + this.getFighterList.findIndex(item => item.id === id))
        var url = ''
        for (var i in this.getFighterList) {
          if (this.getFighterList[i].id === id && typeof this.getFighterList[i].c_photo !== 'undefined') {
            this.imageScr = this.getFighterList[i].c_photo.url
            url = this.getFighterList[i].c_photo.url
          }
        }
        return url
      },
      getWinLosDraw (id) {
        var wld = ''
        for (var i in this.getFighterList) {
          if (this.getFighterList[i].id === id &&  typeof this.getFighterList[i].c_recordWins !== 'undefined') {
            wld = this.getFighterList[i].c_recordWins + '-' + this.getFighterList[i].c_recordLosses + '-' + this.getFighterList[i].c_recordDraws
          }
        }
        return wld
      },
      makeRounds(nr) {
        // console.log(nr)
        let arr = []
          for (var i = 1; i <= nr; i++) {
            // console.log(i)
            let obj = {}
            obj.name = this.lang[this.getLanguage].PREDICTION_ROUNDS + ' ' + i//'Round ' + i
            obj.id = i
            arr.push(obj)
          }
          // console.log(arr)
          return arr
      },
      roundLanguage(num){
        return this.lang[this.getLanguage].PREDICTION_ROUNDS + ' ' + num;
      },
      updatePredictionLanguage(text){
        if([this.lang["id"].PREDICTION_SUBMISSION.toLowerCase(), this.lang["en"].PREDICTION_SUBMISSION.toLowerCase(), this.lang["it"].PREDICTION_SUBMISSION.toLowerCase()].includes(text.toLowerCase())){
          return this.lang[this.getLanguage].PREDICTION_SUBMISSION;
        }
        if([this.lang["id"].PREDICTION_DECISION.toLowerCase(), this.lang["en"].PREDICTION_DECISION.toLowerCase(), this.lang["it"].PREDICTION_DECISION.toLowerCase()].includes(text.toLowerCase())){
          return this.lang[this.getLanguage].PREDICTION_DECISION;
        }
        return text;
      },
      fighterRecordClicked (fighterID) {
        console.log(fighterID)
        if ( this.getFighterList.findIndex(item => item.id === fighterID) === -1) return
        this.selectedFighter = this.getFighterList.find(item => item.id === fighterID)
        this.fighterRecordDialog = true
      },
      gotoLink(link) {
        window.open(link, "_blank");
      },
      selectCorrectTab(t, index){
        // console.log("cstab",t)
        console.log("cstab",index)
        // console.log("cstab",this.combine !== 'no');
        // console.log("cstab",this.eventType !== '');
        // console.log("cstab",this.filteredEventList.length === 0)
        // console.log("cstab",this.filteredEventList)
        if(this.filteredEventList.length === 0) return false;
        if([null,undefined].includes(this.filteredEventList[0].fights)) return false;
        if(this.filteredEventList[0].fights.length === 0) return false;
        if(this.filteredEventList[0].fights.map(item => item.type).includes(t)){
          return true;
        }
        if (this.combine !== 'no'){
          return true;  
        }

        return false;
      },
      checkFirstFightHasStarted (item) {
        let today = this.localServerTime // new Date().getTime()
        let firstFightStarted = item.fights[item.fights.length -1].start
        // console.log(lastFightEnd)
        if (today > firstFightStarted) {
          return true
        }
        return false
      },
      checkLastFightHasEnded (item) {
        let today = this.localServerTime // new Date().getTime()
        let lastFightEnded = item.fights[0].start + (1800 * 1000)
        // console.log(lastFightEnd)
        if (today > lastFightEnded) {
          return true
        }
        return false
      },
      showLiveEventButton (event) {
        if (event.length === 0) return
        if (event.fights.length === 0) return
        let today = this.localServerTime
        let firstFightStarted = event.fights[event.fights.length -1].start - (1000 * 60 * 60) // 1hrs before the game
        let lastFightEnded = event.fights[0].start + (1000 * 60 * 60 * 2) // 2hrs after the game
        console.log("Live", firstFightStarted, lastFightEnded, today);
        if (today > firstFightStarted && today < lastFightEnded && event.type === 'UFC') {
          return true
        }
        return false
      },
      submissionOpen (item) {
        let today = this.localServerTime // new Date().getTime()
        if (today > item.submission_start && today < item.submission_end) {
          return true
        }
        return false
      },
      submissionClosed (item) {
        let today = this.localServerTime // new Date().getTime()
        if (today > item.submission_end) {
          return true
        }
        return false
      },
      checkCancelledFights (item) {
        console.log('################# cancelled fight issue #################')
        console.log(item)
        let length = 0
        for (var i in item.fights)
        if (item.fights[i].status === 1) {
          length+=1
        }
        return length
      },
      showDuelDialog(value) {
        if(!this.getIsLoggedIn){
          this.loginClicked();
          return;
        }
        this.duelDialog = value;
        this.changeUserPrediction();
      },
      showSocialDialog(value) {
        if(!this.getIsLoggedIn){
          this.loginClicked();
          return;
        }
        this.componentKey += 1
        this.socialDialog = value;
      },
      changeUserPrediction(){
        console.log(this.getUser)
        const molaSubs = Array.from(new Set(this.getUserPredictions.map(item => item.mola_sub_type)));
        console.log('subs', molaSubs);
        if(this.getUser.mola_subscription === 1 && molaSubs.some(num => num !== 1)) {
          this.getUserPredictions.filter(obj => obj.mola_sub_type !== 1).forEach(item => {
            item.mola_sub_type = 1;
            this.$store.dispatch('repairPredictionData', {
              predictionId: item.doc_id,
              prediction: item
            })
          })
        }
      },
      async getServerTime () {
        const response = await MolaMfpAPI.getServerTime();
        console.log("Response", response)
        if (typeof response === 'undefined') {
          console.log('Time undefined')
          this.localServerTime = new Date().getTime() / 1000
        } else {
          console.log('Time OK')
          console.log(response.data.data)
          this.localServerTime = response.data.data
        }
        // this.localServerTime = new Date().getTime() / 1000
      },
      getGenderByLang(gender){
        // console.log(gender)
        if([undefined, '', null].includes(gender)){
          return '';
        }
        if(gender.toLowerCase() === 'male'){
          return this.lang[this.getLanguage].MY_PROFILE_GENDER_M
        }
        if(gender.toLowerCase() === 'female'){
          return this.lang[this.getLanguage].MY_PROFILE_GENDER_F
        }
        return '';
      },
      logoutUser () {
        this.$store.dispatch('logout').then(() => {
          // const firstScrollTo = scroller();
          // console.log('Clear userPredictionsArr Array')
          // this.$store.dispatch('clearUserPredictions')
          setTimeout(() => {
            if(this.$route.path !== '/'){
              this.$router.push('/');
            }
            window.location.reload();
            // firstScrollTo('#hometoolbar');
          }, 1000);
        });
      },
      timeDifferenceElapsed(previous) {

        var current = this.localServerTime / 1000 // Math.round(new Date() / 1000)
        // console.log(current)
        var msPerMinute = 60
        var msPerHour = msPerMinute * 60
        var msPerDay = msPerHour * 24
        var msPerMonth = msPerDay * 30
        var msPerYear = msPerDay * 365
        var elapsed = current - previous

        if (elapsed < msPerMinute) {
          return Math.round(elapsed/1000) + this.lang[this.getLanguage].SECONDS_AGO
          ;
        }
        else if (elapsed < msPerHour) {
          return Math.round(elapsed/msPerMinute) + this.lang[this.getLanguage].MINUTES_AGO
        }
        else if (elapsed < msPerDay ) {
          return Math.round(elapsed/msPerHour ) + this.lang[this.getLanguage].HOURS_AGO
        }
        else if (elapsed < msPerMonth) {
          return Math.round(elapsed/msPerDay) + this.lang[this.getLanguage].DAYS_AGO
        }
        else if (elapsed < msPerYear) {
          return Math.round(elapsed/msPerMonth) + this.lang[this.getLanguage].MONTHS_AGO
        }
        else {
          return Math.round(elapsed/msPerYear ) + this.lang[this.getLanguage].YEARS_AGO
        }
      },
      timeDifference(future) {

      var current = this.localServerTime / 1000 // Math.round(new Date() / 1000)
      /// console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = future - current

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + this.lang[this.getLanguage].SECONDS;
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + this.lang[this.getLanguage].MINUTES;
      }
      else if (elapsed < msPerDay ) {
        const hour = Math.floor(elapsed/msPerHour);
        elapsed -= (hour * msPerHour)
        const min = Math.round(elapsed/msPerMinute)
        if(min > 0){
          let hourString = this.lang[this.getLanguage].IN_HOURS_AND_MIN.replace('{x}', hour);
          return hourString.replace('{y}', min);
        }
        return hour + this.lang[this.getLanguage].HOURS;
      }
      else if (elapsed < msPerMonth) {
        return this.lang[this.getLanguage].IN_X_DAYS.replace('{x}', Math.round(elapsed/msPerDay));
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years'
      }
    }
  }
}
</script>
<style>
 @import url('https://fonts.googleapis.com/css2?family=Syncopate:wght@400;700&display=swap');
.wrap-text {
  word-break: normal;
}
.special-fight {
  font-family: "Brother 1816", sans-serif;
  font-weight: 500;
  font-size: 20px;
  font-style: normal;
}
</style>